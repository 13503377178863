import React from 'react';
import { Link } from 'react-router-dom';
import { ChatAlt } from 'heroicons-react';

import { prettyString } from '../../../utils/shared';
import getInviteStyles from '../../styles/inviteStyles';

const OrganisationLink = ({ org, name, path, invite }) => {
    const { bgClassName, textClassName500, textClassName700, borderClassName700 } = getInviteStyles(
        invite?.inviteStatus || '',
    );

    return (
        <Link
            className="flex justify-between bg-gray-200 rounded p-2 mb-2 border"
            key={org}
            to={`/my-account/organisations/all/${org}`}
        >
            <span className="inline-block rounded text-sm px-2 py-1 text-pink-700">{name}</span>
            {invite && (
                <div className="flex items-center">
                    {invite.inviteStatus !== 'APPROVED' && invite.comments?.length > 0 && (
                        <span className={`${textClassName500} mr-2`}>
                            <ChatAlt />
                        </span>
                    )}
                    <span
                        className={`inline-block rounded text-sm px-2 py-1 ${bgClassName} ${textClassName700} border ${borderClassName700} capitalize`}
                    >
                        {prettyString(invite.inviteStatus)}
                    </span>
                </div>
            )}
        </Link>
    );
};

export default OrganisationLink;
