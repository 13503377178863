import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import EventEmitter from '../../utils/EventEmitter';

class PopDispatcher extends EventEmitter {
    addPop({ type, message, sticky, timeout = 3000 }) {
        const id = uuidv4();
        this.emit('add', { type, message, id });
        if (sticky !== true) {
            setTimeout(() => {
                this.emit('remove', id);
            }, timeout);
        }
        return this;
    }
}

export const popDispatcher = new PopDispatcher();

export default function Pop() {
    const [pops, setPops] = useState([]);

    const addPop = (pop) => setPops((pops) => [...pops, pop]);
    const removePop = (id) => setPops((pops) => pops.filter((pop) => pop.id !== id));

    useEffect(() => {
        popDispatcher.addListener('add', addPop);
        popDispatcher.addListener('remove', removePop);
        return () => {
            popDispatcher.removeListener('add', addPop);
            popDispatcher.removeListener('remove', removePop);
        };
    });

    return (
        <div className="fixed bottom-0 right-0 p-2 flex flex-col items-end">
            {pops.map(({ type, message, id }) => {
                let bgClassName = '';
                switch (type) {
                    case 'success':
                        bgClassName = 'bg-green-700';
                        break;
                    case 'warning':
                        bgClassName = 'bg-orange-700';
                        break;
                    case 'error':
                        bgClassName = 'bg-red-700';
                        break;
                    default:
                        bgClassName = 'bg-blue-700';
                        break;
                }
                return (
                    <div
                        className={`${bgClassName} text-white py-2 px-4 mt-2 rounded border border-white cursor-pointer pop`}
                        key={id}
                        onClick={() => removePop(id)}
                    >
                        {message}
                    </div>
                );
            })}
        </div>
    );
}
