import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import Loading from '../../shared/Loading';
import api from '../../../utils/api';

const Redeem = () => {
    const [didError, setDidError] = useState(false);
    const [organisation, setOrganisation] = useState(null);
    const { token } = useParams();

    useEffect(() => {
        const redeemInvite = async () => {
            setOrganisation(null);
            setDidError(false);

            try {
                const { organisation } = await api.redeemInvite(token);
                setOrganisation(organisation);
            } catch (err) {
                console.error(err);
                setDidError(true);
            }
        };

        if (token) {
            redeemInvite();
        }
    }, [token]);

    if (didError) {
        return (
            <p className="text-sm text-red-700 pb-8">
                Sorry, we were unable to redeeming your invitation. Your link may have expired,
                please try requesting another one from the organisation.
            </p>
        );
    }

    if (organisation) {
        return (
            <Redirect
                to={{
                    pathname: `/my-account/organisations/all/${organisation}`,
                    state: { redeemSuccess: true },
                }}
            />
        );
    }

    return (
        <div>
            <Loading />
            <p className="text-sm pb-8">Redeeming your invitation...</p>
        </div>
    );
};

export default Redeem;
