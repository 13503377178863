import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { OnboardingUser } from '@doc-abode/data-models';
import { InformationCircle } from 'heroicons-react';

import Checkbox from '../shared/forms/Checkbox';
import Input from '../shared/forms/Input';
import Radio from '../shared/forms/Radio';
import Recaptcha from '../shared/forms/Recaptcha';
import Select from '../shared/forms/Select';
import SubmitButton from '../shared/forms/SubmitButton';

import api from '../../utils/api';

import { dateToISOString, parseDateString } from '../../utils/shared';

const { primaryGPFunctions, professionalBodies, hcpTypes } = OnboardingUser.lookups;

const professionalBodyRegistrationRequiredOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

const professionalBodyOptions = Object.keys(professionalBodies).map((value) => ({
    value,
    name: professionalBodies[value].name,
    order: professionalBodies[value].order,
    hcpTypes: professionalBodies[value].hcpTypes,
}));

const hcpTypeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    return 1;
};

const today = new Date();

const initialValues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    emailAddress: '',
    emailAddressConfirm: '',
    hcpType: '',
    professionalBodyRegistrationRequired: null,
    professionalBodyType: '',
    professionalBodyTypeOther: '',
    professionalBodyId: '',
    primaryGPFunction: '',
    gmcRegistrationDate: '',
    confirmComplaints: false,
    confirmRestrictions: false,
    confirmOnPerformersList: false,
    confirmNotPerformersListSuspended: false,
    confirmObligations: false,
    confirmAcceptTerms: false,
    recaptcha: null,
};

const validationSchema = Yup.object({
    firstName: Yup.string().required('First name | This field is required.'),

    lastName: Yup.string().required('Last name | This field is required.'),

    mobileNumber: Yup.string().required('Mobiel phone number | This field is required.'),

    emailAddress: Yup.string()
        .email('Email address | Invalid email address provided.')
        .required('Email address | This field is required.'),

    emailAddressConfirm: Yup.string()
        .email('Confirm email address | Invalid email address provided.')
        .required('Confirm email address | This field is required.')
        .test('emails-match', "Confirm email address | Email address doesn't match.", function (
            value,
        ) {
            return this.parent.emailAddress === value;
        }),

    hcpType: Yup.string().required('Professional status | This field is required.'),

    professionalBodyRegistrationRequired: Yup.string()
        .nullable()
        .test(
            'pbr-required',
            'Does your role require you to be registered with a professional body? | This field is required.',
            function (value) {
                return (
                    this.parent.hcpType === 'GP' ||
                    (this.parent.hcpType !== 'GP' && value && value.length > 0)
                );
            },
        ),

    professionalBodyType: Yup.string().test(
        'pbt-required',
        'Professional body | This field is required.',
        function (value) {
            return (
                this.parent.hcpType === 'GP' ||
                this.parent.professionalBodyRegistrationRequired === 'no' ||
                (this.parent.professionalBodyRegistrationRequired === 'yes' &&
                    value &&
                    value.length > 0)
            );
        },
    ),

    professionalBodyTypeOther: Yup.string().when(
        'professionalBodyType',
        (professionalBodyType, schema) => {
            return professionalBodyType === 'other'
                ? schema.required('Professional Body (other) | This field is required.')
                : schema;
        },
    ),

    professionalBodyId: Yup.string()
        .test('pbid-required', 'Professional body ID | This field is required.', function (value) {
            return (
                (this.parent.hcpType === 'GP' && value && value.length > 0) ||
                this.parent.professionalBodyRegistrationRequired === 'no' ||
                (this.parent.professionalBodyRegistrationRequired === 'yes' &&
                    value &&
                    value.length > 0)
            );
        })
        .test('pbid-format', '', function (value) {
            const isGP = this.parent.hcpType === 'GP';
            const selectedProfessionalBodyType = isGP ? 'GMC' : this.parent.professionalBodyType;
            const selectedProfessionalBody = selectedProfessionalBodyType
                ? professionalBodies[selectedProfessionalBodyType]
                : null;

            if (!selectedProfessionalBody || !value || value.length < 1) {
                return true;
            }

            const regexToUse = selectedProfessionalBody ? selectedProfessionalBody.regex : null;

            if (!regexToUse) {
                return true;
            }

            const re = new RegExp(regexToUse);
            const message = `Professional body ID | ${
                selectedProfessionalBody.regexHelp || 'The provided ID format is incorrect.'
            }`;
            return re.test(value) || this.createError({ message });
        }),

    primaryGPFunction: Yup.string().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.required('What is your primary GP function? | This field is required.')
            : schema;
    }),

    gmcRegistrationDate: Yup.date()
        .transform(parseDateString)
        .typeError('Date of full GMC registration | Date must be in the format DD/MM/YYYY.')
        .max(today, 'Date of full GMC registration | Date entered must be in the past.')
        .when('hcpType', (hcpType, schema) => {
            return hcpType === 'GP'
                ? schema.required('Date of full GMC registration | This field is required.')
                : schema;
        }),

    confirmComplaints: Yup.boolean().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.oneOf(
                  [true],
                  'I am not aware of any complaints against me that have been referred to independent review by NHSE or the GMC | This field is required.',
              )
            : schema;
    }),

    confirmRestrictions: Yup.boolean().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.oneOf(
                  [true],
                  'I have no restrictions on practice or changes in GMC registration status | This field is required.',
              )
            : schema;
    }),

    confirmOnPerformersList: Yup.boolean().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.oneOf(
                  [true],
                  'I am currently included on the NHS England Performers List | This field is required.',
              )
            : schema;
    }),

    confirmNotPerformersListSuspended: Yup.boolean().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.oneOf(
                  [true],
                  'I have not been suspended from the NHS England Performers List | This field is required.',
              )
            : schema;
    }),

    confirmObligations: Yup.boolean().when('hcpType', (hcpType, schema) => {
        return hcpType === 'GP'
            ? schema.oneOf(
                  [true],
                  'I accept the professional obligations placed on me in Good Medical Practice paragraphs 28-30 (GMC 2013) about my personal health | This field is required.',
              )
            : schema;
    }),

    confirmAcceptTerms: Yup.boolean().oneOf(
        [true],
        'I accept the Doc Abode Terms and Conditions | This field is required.',
    ),

    recaptcha: Yup.string().nullable().required('Please complete the Recaptcha.'),
});

const HCPApplication = () => {
    const [didComplete, setDidComplete] = useState(false);
    const [didError, setDidError] = useState(false);
    const [didConflict, setDidConflict] = useState(false);

    const params = useParams();
    const campaign = params.campaign?.toLowerCase();

    const onSubmit = async (values, { setSubmitting }) => {
        const formattedPayload = {
            name: `${values.firstName} ${values.lastName}`,
            phoneNumber: values.mobileNumber.replace(/\s+/g, ''),
            email: values.emailAddress,
            hcpType: values.hcpType,
            professionalBodyRegistrationRequired:
                values.professionalBodyRegistrationRequired === 'yes',
            confirmComplaints: values.confirmComplaints === true,
            confirmRestrictions: values.confirmRestrictions === true,
            confirmOnPerformersList: values.confirmOnPerformersList === true,
            confirmNotPerformersListSuspended: values.confirmNotPerformersListSuspended === true,
            confirmObligations: values.confirmObligations === true,
            confirmAcceptTerms: values.confirmAcceptTerms === true,
            recaptcha: values.recaptcha,
            campaign,
            onboardingLite: campaign === 'carebyte',
        };

        if (values.hcpType === 'GP') {
            formattedPayload.professionalBodyType = 'GMC';
            formattedPayload.gmcRegistrationDate = dateToISOString(values.gmcRegistrationDate);
        } else if (values.professionalBodyType && values.professionalBodyType.length > 0) {
            formattedPayload.professionalBodyType = values.professionalBodyType;
        }

        if (values.professionalBodyId && values.professionalBodyId.length > 0) {
            formattedPayload.professionalBodyId = values.professionalBodyId;
        }

        if (values.primaryGPFunction && values.primaryGPFunction.length > 0) {
            formattedPayload.primaryGPFunction = values.primaryGPFunction;
        }

        if (formattedPayload.professionalBodyType === 'other') {
            formattedPayload.professionalBodyTypeOther = values.professionalBodyTypeOther;
        }

        try {
            await api.hcpPrescreen(formattedPayload);

            setSubmitting(false);
            setDidError(false);
            setDidConflict(false);
            setDidComplete(true);
        } catch (error) {
            setSubmitting(false);
            console.error(error.response);
            if (error.response?.status === 409) {
                setDidConflict(true);
            } else {
                setDidError(true);
            }
        }
    };

    return (
        <>
            <p className="text-2xl font-light text-pink-700 mb-4">
                Healthcare Professional Registration
            </p>

            {didComplete && (
                <div>
                    <p className="mx-5">
                        Thanks, we have received your application and will be in touch in due
                        course.
                    </p>
                    <p className="mx-5 mt-5">
                        Once your application has been approved, you will receive an email with a
                        username and temporary password that you can use to{' '}
                        <Link className="text-pink-700 underline" to="/my-account">
                            sign in
                        </Link>{' '}
                        and continue the onboarding process.
                    </p>
                </div>
            )}

            {!didComplete && (
                <>
                    {campaign === 'carebyte' ? (
                        <div className="text-sm text-blue-700 rounded py-2 px-4 mb-4 bg-blue-100 border border-blue-600 text-left flex">
                            <span>
                                <InformationCircle className="mr-4" />
                            </span>
                            <p>
                                This registration form is for doctors taking part in the Carebyte
                                India crisis response. If you have arrived at this page in error,
                                please{' '}
                                <Link className="text-pink-700 underline" to="/register/individual">
                                    register here instead
                                </Link>
                                .
                            </p>
                        </div>
                    ) : (
                        <p className="text-sm mb-8 text-left">
                            This registration form is for Healthcare Professionals (HCPs) to submit
                            the necessary information for verification in order to work on cases
                            managed and assigned through the Doc Abode platform.
                        </p>
                    )}

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, isSubmitting }) => {
                            const didSelectGP =
                                values.hcpType &&
                                values.hcpType.length > 0 &&
                                values.hcpType === 'GP';
                            const professionalBodyRequired =
                                values.professionalBodyRegistrationRequired === 'yes' &&
                                !didSelectGP;
                            const selectedProfessionalBodyType =
                                values.professionalBodyType &&
                                values.professionalBodyType.length > 0
                                    ? professionalBodies[values.professionalBodyType]
                                    : null;

                            return (
                                <Form className="text-left">
                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                        Personal Details
                                    </p>

                                    <Input
                                        name="firstName"
                                        label="First Name"
                                        placeholder="e.g. Jane"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="lastName"
                                        label="Last Name"
                                        placeholder="e.g. Smith"
                                        disabled={isSubmitting}
                                    />

                                    <Input
                                        name="mobileNumber"
                                        label="Mobile Phone Number"
                                        placeholder="e.g. +44 7738 123 456"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="emailAddress"
                                        type="email"
                                        label="Email Address"
                                        placeholder="e.g. jane.smith@example.com"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="emailAddressConfirm"
                                        type="email"
                                        label="Confirm Email Address"
                                        placeholder="e.g. jane.smith@example.com"
                                        disabled={isSubmitting}
                                    />

                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                        Professional Details
                                    </p>

                                    <Select
                                        name="hcpType"
                                        label="Professional Status"
                                        disabled={isSubmitting}
                                    >
                                        <option value="">Please select...</option>
                                        {hcpTypes.sort(hcpTypeSort).map((option) => {
                                            return (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </Select>

                                    {values.hcpType && !didSelectGP && (
                                        <Radio
                                            label="Does your role require you to be registered with a professional body?"
                                            name="professionalBodyRegistrationRequired"
                                            options={professionalBodyRegistrationRequiredOptions}
                                            disabled={isSubmitting}
                                        />
                                    )}

                                    {professionalBodyRequired && (
                                        <div>
                                            <Select
                                                name="professionalBodyType"
                                                label="Professional Body"
                                                disabled={isSubmitting}
                                            >
                                                <option value="">Please select...</option>
                                                {professionalBodyOptions
                                                    .filter((option) =>
                                                        option.hcpTypes
                                                            ? option.hcpTypes.includes(
                                                                  values.hcpType,
                                                              )
                                                            : true,
                                                    )
                                                    .map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.name}
                                                        </option>
                                                    ))}
                                            </Select>

                                            {values.professionalBodyType === 'other' && (
                                                <Input
                                                    name="professionalBodyTypeOther"
                                                    placeholder="Enter the professional body's name here"
                                                    disabled={isSubmitting}
                                                />
                                            )}

                                            <Input
                                                name="professionalBodyId"
                                                label="Professional Body ID"
                                                placeholder={
                                                    selectedProfessionalBodyType
                                                        ? selectedProfessionalBodyType.placeholder
                                                        : null
                                                }
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    )}

                                    {didSelectGP && (
                                        <div>
                                            <Select
                                                name="primaryGPFunction"
                                                label="What is your primary GP function?"
                                                disabled={isSubmitting}
                                            >
                                                <option value="">Please select...</option>
                                                {primaryGPFunctions.map((option) => (
                                                    <option key={option}>{option}</option>
                                                ))}
                                            </Select>

                                            <Input
                                                name="professionalBodyId"
                                                label="GMC Registration Number"
                                                placeholder={professionalBodies.GMC.placeholder}
                                                disabled={isSubmitting}
                                            />

                                            <Input
                                                name="gmcRegistrationDate"
                                                label="Date of full GMC registration"
                                                placeholder="e.g. 14/06/1995"
                                                disabled={isSubmitting}
                                            />

                                            <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                                Declarations
                                            </p>

                                            <Checkbox
                                                name="confirmComplaints"
                                                disabled={isSubmitting}
                                            >
                                                I am not aware of any complaints against me that
                                                have been referred to independent review by NHSE or
                                                the GMC.
                                            </Checkbox>

                                            <Checkbox
                                                name="confirmRestrictions"
                                                disabled={isSubmitting}
                                            >
                                                I have no restrictions on practice or changes in GMC
                                                registration status.
                                            </Checkbox>

                                            <Checkbox
                                                name="confirmOnPerformersList"
                                                disabled={isSubmitting}
                                            >
                                                I am currently included on the{' '}
                                                <a
                                                    className="text-pink-700 underline"
                                                    href="https://secure.pcse.england.nhs.uk/PerformersLists"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    NHS England Performers List
                                                </a>
                                                .
                                            </Checkbox>

                                            <Checkbox
                                                name="confirmNotPerformersListSuspended"
                                                disabled={isSubmitting}
                                            >
                                                I have not been suspended from the{' '}
                                                <a
                                                    className="text-pink-700 underline"
                                                    href="https://secure.pcse.england.nhs.uk/PerformersLists"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    NHS England Performers List
                                                </a>
                                                .
                                            </Checkbox>

                                            <Checkbox
                                                name="confirmObligations"
                                                disabled={isSubmitting}
                                            >
                                                I accept the professional obligations placed on me
                                                in{' '}
                                                <a
                                                    className="text-pink-700 underline"
                                                    href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/good-medical-practice/domain-2----safety-and-quality#paragraph-28"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Good Medical Practice paragraphs 28-30 (GMC
                                                    2013)
                                                </a>{' '}
                                                about my personal health.
                                            </Checkbox>
                                        </div>
                                    )}

                                    <Checkbox name="confirmAcceptTerms" disabled={isSubmitting}>
                                        I accept the Doc Abode{' '}
                                        <a
                                            className="text-pink-700 underline"
                                            href="https://docabode.com/terms-of-use"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms and Conditions
                                        </a>
                                        .
                                    </Checkbox>

                                    <Recaptcha />

                                    <div className="pt-2">
                                        {didError && (
                                            <p className="text-sm text-center text-red-700 mb-4">
                                                We were unable to process your application. Please
                                                try again.
                                            </p>
                                        )}

                                        {didConflict && (
                                            <p className="text-sm text-center text-red-700 mb-4">
                                                Your email address is alredy registered with Doc
                                                Abode. If it is correct, please{' '}
                                                <Link
                                                    to="/my-account"
                                                    className="text-pink-700 underline"
                                                >
                                                    sign into your account
                                                </Link>{' '}
                                                instead. If you have forgotten your user name or
                                                password, you can request a reminder/reset on the
                                                sign in page.
                                            </p>
                                        )}

                                        <SubmitButton
                                            isSubmitting={isSubmitting}
                                            text="Submit My Details"
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};

export default HCPApplication;
