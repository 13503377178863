import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Loading from './shared/Loading';
import api from '../utils/api';

const Redirect = () => {
    const [operatorId, setOperatorId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);
    const { org, token } = useParams();

    useEffect(() => {
        const getCredentials = async () => {
            let credentials;

            try {
                if (token) {
                    credentials = await api.decodeToken(org, token);
                } else {
                    credentials = await api.getCredentials(org);
                }

                setOperatorId(credentials.operatorId);
                setPassword(credentials.password);

                document.querySelector('form').submit();
            } catch (err) {
                console.error(err);
                setDidError(true);
                setLoading(false);
            }
        };

        getCredentials();
    }, [org, token]);

    return (
        <div>
            {loading && (
                <>
                    <Loading />
                    <p className="text-sm pb-8">Transferring you, please wait a moment...</p>
                </>
            )}
            {didError && token && (
                <p className="text-sm text-red-700 pb-8">
                    Sorry, we were unable to transfer you. Your link may have expired, please try
                    generating another one from the mobile app.
                </p>
            )}
            {didError && !token && (
                <>
                    <p className="text-sm text-red-700 pb-4">
                        Sorry, we were unable to transfer you.
                    </p>
                    <Link
                        to={`/my-account/organisations/all/${org}`}
                        className="inline-block rounded bg-pink-700 text-white px-6 py-2 mb-4"
                    >
                        Return to organisation page
                    </Link>
                </>
            )}
            <form action="https://docabode.call-vision.com/app_launch.asp" method="POST">
                <input type="hidden" name="u" value={operatorId} />
                <input type="hidden" name="p" value={password} />
            </form>
        </div>
    );
};

export default Redirect;
