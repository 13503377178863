import React from 'react';
import { Switch, Route, useLocation, Link } from 'react-router-dom';

import '../assets/css/tailwind.output.css';
import logo from '../assets/images/docabode_logo.png';

import HCPApplication from './register/HCPApplication';
import Intro from './Intro';
import Transfer from './Transfer';
import Authenticator from './myaccount/auth/Authenticator';
import MyAccount from './myaccount/Index';
import OrganisationApplication from './register/OrganisationApplication';
import Pop from './shared/Pop';

const ENV = process.env.REACT_APP_ENVIRONMENT;

const Index = () => {
    const location = useLocation();

    return (
        <div className="p-4">
            <div className="max-w-2xl mx-auto bg-gray-100 md:my-4 border rounded text-center overflow-hidden shadow-md">
                {ENV !== 'prod' && (
                    <div className="bg-pink-700 text-white">
                        <p className="px-12 py-3 text-sm">
                            This is the <strong>{ENV}</strong> environment. Any actions perfomed
                            here will not affect production systems.
                        </p>
                    </div>
                )}

                <Link to="/">
                    <img
                        src={logo}
                        className={`${
                            location.pathname === '/' ? 'h-24' : 'h-16'
                        } mx-auto mt-6 mb-6 transition-all duration-300`}
                        alt="Doc Abode logo"
                    />
                </Link>

                <div className="p-3 md:p-6 pt-0">
                    <Switch>
                        <Route path="/register/individual/:campaign?">
                            <HCPApplication />
                        </Route>
                        <Route path="/register/organisation">
                            <OrganisationApplication />
                        </Route>
                        <Route path="/my-account">
                            <Authenticator>
                                <MyAccount />
                            </Authenticator>
                        </Route>
                        <Route path="/transfer/org/:org/(token)?/:token?">
                            <Transfer />
                        </Route>
                        <Route>
                            <Intro />
                        </Route>
                    </Switch>
                </div>
            </div>
            <Pop />
        </div>
    );
};

export default Index;
