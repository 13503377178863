import React from 'react';

const SubmitButton = ({
    isSubmitting = false,
    text = 'Submit',
    onSubmit,
    small = false,
    className = '',
    type = 'submit',
}) => {
    return (
        <button
            className={`rounded bg-pink-700 text-white p-6 ${
                isSubmitting ? 'cursor-not-allowed' : ''
            } ${small ? 'inline-block px-6 py-2' : 'block p-6 w-full'} ${className}`}
            disabled={isSubmitting}
            type={type}
            onClick={onSubmit}
        >
            {isSubmitting && (
                <div className="inline-flex items-center">
                    <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Please wait...
                </div>
            )}
            {!isSubmitting && text}
        </button>
    );
};

export default SubmitButton;
