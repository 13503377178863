import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';
import Radio from '../../shared/forms/Radio';

const VerifyEmail = ({ didError, onVerifyContact, onRequestCode }) => {
    const [codeSent, setCodeSent] = useState(false);
    const [contactType, setContactType] = useState(false);

    const handleRequestCode = async ({ contactType }) => {
        setContactType(contactType);
        await onRequestCode(contactType);
        setCodeSent(true);
    };

    if (codeSent) {
        return (
            <Formik
                initialValues={{
                    code: '',
                    contactType,
                }}
                onSubmit={onVerifyContact}
            >
                {({ isSubmitting }) => {
                    const contactTypeText =
                        contactType === 'email' ? 'Email Address' : 'Phone Number';
                    return (
                        <Form className="text-left">
                            <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                Verify Your {contactTypeText}
                            </p>

                            <Input
                                name="code"
                                type="text"
                                inputmode="numeric"
                                label={`Please enter the verifcation code sent to your ${contactTypeText.toLowerCase()}`}
                                placeholder="e.g. 123456"
                                disabled={isSubmitting}
                            />

                            <p className="text-sm mb-6">
                                <button
                                    type="button"
                                    className="text-pink-700 underline"
                                    onClick={() => onRequestCode(contactType)}
                                >
                                    Resend Code
                                </button>
                            </p>

                            <SubmitButton
                                isSubmitting={isSubmitting}
                                text={`Verify your ${contactTypeText.toLowerCase()}`}
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    return (
        <Formik
            initialValues={{
                contactType: '',
            }}
            onSubmit={handleRequestCode}
        >
            {({ values, isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Verify Your Contact Details
                        </p>

                        <p className="text-sm mb-6">
                            We need to verify either your email address or phone number before you
                            proceed further. Please choose the option you prefer below and then
                            enter the verification code when prompted.
                        </p>

                        <Radio
                            label="Choose contact method to verify"
                            name="contactType"
                            options={[
                                { label: 'Email address', value: 'email' },
                                { label: 'Phone number', value: 'phone_number' },
                            ]}
                            disabled={isSubmitting}
                            small
                        />

                        <SubmitButton isSubmitting={isSubmitting} text="Send Verification Code" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default VerifyEmail;
