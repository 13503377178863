import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import config from './utils/config';

import './assets/css/tailwind.output.css';
import Index from './components/Index';

Amplify.configure(config.aws);

const App = () => {
    return (
        <Router>
            <Index />
        </Router>
    );
};

export default App;
