import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';
import Recaptcha from '../../shared/forms/Recaptcha';

const ForgotUsername = ({ didError, onBackToSignIn, onForgotUsername }) => {
    const initialValues = {
        email: '',
        recaptcha: null,
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Email address | Invalid email address provided.')
            .required('Email address | This field is required.'),
        recaptcha: Yup.string().nullable().required('Please complete the Recaptcha.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onForgotUsername}
        >
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Forgot User Name
                        </p>

                        <p className="text-sm mb-4">
                            Please enter your email address below and if it matches an account in
                            our records, we will send an email to that address containing your user
                            name.
                        </p>

                        <Input
                            name="email"
                            label="Email Address"
                            placeholder="e.g. jane.smith@example.com"
                            disabled={isSubmitting}
                        />

                        <Recaptcha />

                        <p className="text-sm mb-6">
                            <button
                                type="button"
                                className="text-pink-700 underline"
                                onClick={onBackToSignIn}
                            >
                                Back to sign in
                            </button>
                        </p>

                        <SubmitButton
                            isSubmitting={isSubmitting}
                            text="Send Me an Email Reminder"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ForgotUsername;
