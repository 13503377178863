import { isDate, parse, isValid, parseISO, format } from 'date-fns';

export const parseDateString = (value, originalValue) => {
    if (!originalValue) return new Date();

    const parsedDate = isDate(originalValue)
        ? originalValue
        : originalValue.length === 10
        ? parse(originalValue, 'dd/MM/yyyy', new Date())
        : new Date('');

    return parsedDate;
};

export const dateToISOString = (value) => {
    const dt = parse(value, 'dd/MM/yyyy', new Date());

    return isDate(dt) && isValid(dt) ? dt.toISOString() : '';
};

export const prettyDate = (value) => {
    const dt = parseISO(value);

    return isDate(dt) && isValid(dt) ? format(dt, 'dd/MM/yyyy') : '';
};

export const prettyDateTime = (value) => {
    const dt = parseISO(value);

    return isDate(dt) && isValid(dt) ? format(dt, "dd/MM/yyyy 'at' HH:mm") : '';
};

export const logJSON = (value) => console.log(JSON.stringify(value, null, 2));

export const pick = (object, keys) =>
    keys.reduce((obj, key) => {
        if (object && object.hasOwnProperty(key)) {
            obj[key] = object[key];
        }
        return obj;
    }, {});

export const omit = (obj, props) => {
    obj = { ...obj };
    props.forEach((prop) => delete obj[prop]);
    return obj;
};

export const boolToString = (value) => (value ? 'yes' : 'no');

export const stringToBool = (value) => value === 'yes';

export const prettyString = (string) => string.toLowerCase().replace(/_/g, ' ');

export const sortAlpha = (a, b) => {
    if (a.name < b.name) {
        return -1;
    }

    return 1;
};
