import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

export const AUTH_STATUS = {
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
    SMS_MFA: 'SMS_MFA',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    LOGGED_IN: 'LOGGED_IN',
};

export const getSession = async () => {
    try {
        const session = await Auth.currentSession();

        const userGroups = session.idToken.payload['cognito:groups'] || [];
        const username = session.idToken.payload['cognito:username'];

        Sentry.configureScope((scope) => {
            scope.setUser({ username });
        });

        return {
            tokens: {
                access: session.accessToken.jwtToken,
                id: session.idToken.jwtToken,
                refresh: session.refreshToken.token,
            },
            userGroups: userGroups,
            username,
            email: session.idToken.payload.email,
            emailVerified: session.idToken.payload.email_verified,
            phoneNumber: session.idToken.payload.phone_number,
            phoneNumberVerified: session.idToken.payload.phone_number_verified,
            organisations: userGroups
                .filter((group) => group.startsWith('org-'))
                .map((group) => group.substring(4)),
        };
    } catch (error) {
        if (error !== 'No current user') {
            console.error(error);
        }
        return null;
    }
};

export const signIn = async (username, password) => {
    const user = await Auth.signIn(username, password);
    const emailVerified = user?.attributes?.email_verified;
    const phoneNumberVerified = user?.attributes?.phone_number_verified;

    return {
        status:
            user.challengeName ||
            (emailVerified || phoneNumberVerified
                ? AUTH_STATUS.LOGGED_IN
                : AUTH_STATUS.VERIFICATION_REQUIRED),
        user,
    };
};

export const completeNewPassword = async (user, newPassword) => {
    const result = await Auth.completeNewPassword(user, newPassword);
    const emailVerified = user?.attributes?.email_verified;
    const phoneNumberVerified = user?.attributes?.phone_number_verified;

    return {
        status:
            result.challengeName ||
            (emailVerified || phoneNumberVerified
                ? AUTH_STATUS.LOGGED_IN
                : AUTH_STATUS.VERIFICATION_REQUIRED),
        user,
    };
};

export const forgotPassword = (username) => {
    return Auth.forgotPassword(username);
};

export const resetPassword = (username, code, newPassword) => {
    return Auth.forgotPasswordSubmit(username, code.toString(), newPassword);
};

export const verifyCurrentUserAttribute = (contactType) => {
    return Auth.verifyCurrentUserAttribute(contactType);
};

export const verifyCurrentUserAttributeSubmit = (contactType, code) => {
    return Auth.verifyCurrentUserAttributeSubmit(contactType, code);
};

export const confirmMFACode = (user, code, mfaType) => {
    return Auth.confirmSignIn(user, code.toString(), mfaType);
};

export const signOut = () => {
    return Auth.signOut();
};
