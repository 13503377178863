export default class EventEmitter {
    constructor() {
        this._events = {};
    }

    emit(event, payload) {
        if (Array.isArray(this._events[event])) {
            this._events[event].forEach((callback) => callback(payload));
        }
    }

    addListener(event, callback) {
        this._events[event] = this._events[event] || [];
        const index = this._events[event].indexOf(callback);
        if (index === -1) {
            this._events[event].push(callback);
        }
        return this;
    }

    removeListener(event, callback) {
        if (Array.isArray(this._events[event])) {
            const index = this._events[event].indexOf(callback);
            if (index > -1) {
                this._events[event].splice(index, 1);
            }
        }
        return this;
    }
}
