import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, NavLink, Redirect } from 'react-router-dom';
import { OnboardingUser } from '@doc-abode/data-models';

import api from '../../utils/api';
import OnboardingWizard from './onboarding/Wizard';
import MyPassport from './onboarding/MyPassport';
import AllOrganisations from './organisations/AllOrganisations';
import MyOrganisations from './organisations/MyOrganisations';
import Redeem from './organisations/Redeem';

const Index = () => {
    const [didError, setDidError] = useState('');
    const [isFetchingOnboarding, setIsFetchingOnboarding] = useState(true);
    const [isFetchingInvites, setIsFetchingInvites] = useState(true);
    const [onboarding, setOnboarding] = useState({});
    const [invites, setInvites] = useState([]);

    const { path } = useRouteMatch();

    const fetchOnboarding = async () => {
        setIsFetchingOnboarding(true);

        try {
            const onboardingResult = await api.getOnboarding();
            const onboardingUser = new OnboardingUser();
            onboardingUser.import(onboardingResult);
            setOnboarding(onboardingUser);
            setIsFetchingOnboarding(false);
        } catch (err) {
            console.error(err);
            setDidError(err.message);
        }
    };

    const fetchInvites = async () => {
        setIsFetchingInvites(true);

        try {
            const currentInvites = await api.getInvites();
            setInvites(currentInvites);
            setIsFetchingInvites(false);
        } catch (err) {
            console.error(err);
            setDidError(err.message);
        }
    };

    useEffect(() => {
        fetchOnboarding();
        fetchInvites();
    }, []);

    return (
        <>
            <nav className="rounded bg-white flex mb-6">
                <NavLink
                    className="w-1/2 rounded text-pink-700 flex items-center justify-center m-2 p-2"
                    activeClassName="bg-blue-800"
                    activeStyle={{ color: 'white' }}
                    to={`${path}/organisations/`}
                >
                    My Organisations
                </NavLink>
                <NavLink
                    className="w-1/2 rounded text-pink-700 flex items-center justify-center m-2 p-2"
                    activeClassName="bg-blue-800"
                    activeStyle={{ color: 'white' }}
                    to={`${path}/passport/`}
                >
                    My Passport
                </NavLink>
            </nav>

            <Switch>
                <Redirect exact from={path} to={`${path}/organisations`} />
                <Route path={`${path}/passport/onboarding/steps/:step?`}>
                    <OnboardingWizard onboarding={onboarding} loading={isFetchingOnboarding} />
                </Route>
                <Route path={`${path}/passport`}>
                    <MyPassport onboarding={onboarding} loading={isFetchingOnboarding} />
                </Route>
                <Route path={`${path}/organisations/all`}>
                    <AllOrganisations
                        invites={invites}
                        setInvites={setInvites}
                        onboarding={onboarding}
                        loading={isFetchingInvites || isFetchingOnboarding}
                    />
                </Route>
                <Route path={`${path}/organisations`}>
                    <MyOrganisations
                        invites={invites}
                        onboarding={onboarding}
                        loading={isFetchingInvites || isFetchingOnboarding}
                    />
                </Route>
                <Route path={`${path}/redeem/:token`}>
                    <Redeem />
                </Route>
            </Switch>

            {didError && <p className="text-sm">Error: {didError}</p>}
        </>
    );
};

export default Index;
