export const deviceOptions = [
    { value: 'iphone', label: 'iPhone' },
    { value: 'android', label: 'Android' },
    { value: 'unknown', label: "I'm not sure" },
];

export const yesNoOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

export const pensionTierDeductionOptions = [5, 5.6, 7.1, 9.3, 12.5, 13.5, 14.5];

export const tradingStatusOptions = [
    { label: 'An individual healthcare professional', value: 'INDIVIDUAL' },
    { label: 'A limited company', value: 'COMPANY' },
];

export const hcpTypes = [
    { value: 'GP', label: 'GP' },
    { value: 'ANP', label: 'Advanced Nurse Practitioner' },
    { value: 'care_coordinator', label: 'Care Co-ordinator' },
    { value: 'pharmacist', label: 'Clinical Pharmacist' },
    { value: 'dietician', label: 'Dietician' },
    { value: 'health_coach', label: 'Health and Wellbeing Coach' },
    { value: 'occupational_therapist', label: 'Occupational Therapist' },
    { value: 'pharmacy_technician', label: 'Pharmacy Technician' },
    { value: 'physician_associate', label: 'Physician Associate' },
    { value: 'practice_nurse', label: 'Practice Nurse' },
    { value: 'link_worker', label: 'Social Prescribing Link Worker' },
    { value: 'podiatrist', label: 'Podiatrist' },
    { value: 'dentist', label: 'Dentist' },
    { value: 'sonographer', label: 'Sonographer' },
    { value: 'physiotherapist', label: 'Physiotherapist' },
    { value: 'hospital_specialist', label: 'Hospital Doctor/Specialist' },
    { value: 'speech_specialist', label: 'Speech and Language Specialist' },
    { value: 'AHP', label: 'Other Allied Health Professional' },
];

export const professionalBodies = {
    GMC: {
        name: 'General Medical Council',
        regex: '^\\d{7}$',
        regexHelp: 'General Medical Council ID should be 7 numeric digits.',
        placeholder: 'e.g. 1234567',
        hcpTypes: ['GP', 'hospital_specialist'],
        order: 1,
    },
    HCPC: {
        name: 'Health & Care Professions Council',
        regex: '^[A-Z]{2,3}\\d{5,6}$',
        regexHelp:
            'Health & Care Professions Council ID should be 2 or 3 uppercase letters followed by 5 or 6 digits.',
        placeholder: 'e.g. PH118913, ODP10136, HAD01174',
        hcpTypes: [
            'speech_specialist',
            'physiotherapist',
            'podiatrist',
            'dietician',
            'occupational_therapist',
        ],
        order: 2,
    },
    NMC: {
        name: 'Nursing Midwifery Council',
        regex: '^\\d{2}[A-Z]{1}\\d{4}[A-Z]{1}$',
        regexHelp:
            'Nursing Midwifery Council ID should be 2 numbers, one uppercase letter, 4 numbers, one uppercase letter.',
        placeholder: 'e.g. 12A3456B',
        hcpTypes: ['ANP', 'practice_nurse'],
        order: 3,
    },
    GPhC: {
        name: 'General Pharmaceutical Council',
        regex: '^\\d{7}$',
        regexHelp: 'General Pharmaceutical Council ID should be 7 numeric digits.',
        placeholder: 'e.g. 1234567',
        hcpTypes: ['pharmacist'],
        order: 4,
    },
    GDC: {
        name: 'General Dental Council',
        regex: '^\\d{5,6}$',
        regexHelp: 'General Dental Council ID should be 5 or 6 numeric digits.',
        placeholder: 'e.g. 12345',
        hcpTypes: ['dentist'],
        order: 5,
    },
    other: {
        name: 'Other (please specify)',
        order: 6,
    },
};

export const primaryGPFunctions = ['Locum', 'Partner', 'Salaried GP', 'Mixture'];

export const equalityOptions = {
    gender: ['Female', 'Male', 'Prefer not to say', 'Let me type...'],
    maritalStatus: [
        'Civil partnership',
        'Divorced',
        'Legally separated',
        'Married',
        'Single',
        'Unknown',
        'Widowed',
        'Prefer not to say',
    ],
    ethnicOrigin: [
        'White - British',
        'White - Irish',
        'White - Any other White background',
        'White Northern Irish',
        'White Unspecified',
        'White English',
        'White Scottish',
        'White Welsh',
        'White Cornish',
        'White Cypriot (non specific)',
        'White Greek',
        'White Greek Cypriot',
        'White Turkish',
        'White Turkish Cypriot',
        'White Italian',
        'White Irish Traveller',
        'White Traveller',
        'White Gypsy/Romany',
        'White Polish',
        'White ex-USSR',
        'White Kosovan',
        'White Albanian',
        'White Bosnian',
        'White Croatian',
        'White Serbian',
        'White Other Ex-Yugoslav',
        'White Mixed',
        'White Other European',
        'Mixed - White & Black Caribbean',
        'Mixed - White & Black African',
        'Mixed - White & Asian',
        'Mixed - Any other mixed background',
        'Mixed - Black & Asian',
        'Mixed - Black & Chinese',
        'Mixed - Black & White',
        'Mixed - Chinese & White',
        'Mixed - Asian & Chinese',
        'Mixed - Other/Unspecified',
        'Asian or Asian British - Indian',
        'Asian or Asian British - Pakistani',
        'Asian or Asian British - Bangladeshi',
        'Asian or Asian British - Any other Asian background',
        'Asian Mixed',
        'Asian Punjabi',
        'Asian Kashmiri',
        'Asian East African',
        'Asian Sri Lankan',
        'Asian Caribbean',
        'Asian Tamil',
        'Asian Sinhalese',
        'Asian British',
        'Asian Unspecified',
        'Black or Black British - Caribbean',
        'Black or Black British - African',
        'Black or Black British - Any other Black background',
        'Black Somali',
        'Black Mixed',
        'Black Nigerian',
        'Black British',
        'Black Unspecified',
        'Chinese',
        'Vietnamese',
        'Japanese',
        'Filipino',
        'Malaysian',
        'Any Other Ethnic Group',
        'Prefer not to say',
    ],
    countryOfBirth: [
        'Afghanistan',
        'Aland Islands',
        'Albania',
        'Algeria',
        'American Samoa',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antarctica',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia, Plurinational State of',
        'Bosnia and Herzegovina',
        'Botswana',
        'Bouvet Island',
        'Brazil',
        'British Indian Ocean Territory',
        'Brunei Darussalam',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Cayman Islands',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Christmas Island',
        'Cocos (Keeling) Islands',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo, The Democratic Republic of the',
        'Cook Islands',
        'Costa Rica',
        "Cote d'Ivoire",
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Guiana',
        'French Polynesia',
        'French Southern Territories',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Heard Island and McDonald Islands',
        'Holy See (Vatican City State)',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran, Islamic Republic of',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        "Korea, Democratic People's Republic of",
        'Korea, Republic of',
        'Kuwait',
        'Kyrgyzstan',
        "Lao People's Democratic Republic",
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libyan Arab Jamahiriya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macao',
        'Macedonia, The Former Yugoslav Republic of',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Martinique',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Micronesia, Federated States of',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Niue',
        'Norfolk Island',
        'Northern Mariana Islands',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestinian Territory,Occupied',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Pitcairn',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'Saint Barthelemy',
        'Saint Helena',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Martin (French part)',
        'Saint Pierre and Miquelon',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Georgia and the South Sandwich Islands',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Svalbard and Jan Mayen',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syrian Arab Republic',
        'Taiwan',
        'Tajikistan',
        'Tanzania, United Republic of',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks and Caicos Islands',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'United States Minor Outlying Islands',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela, Bolivarian Republic of',
        'Viet Nam',
        'Virgin Islands, British',
        'Virgin Islands, U.S.',
        'Wallis and Futuna',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe',
        'Prefer not to say',
    ],
    nationality: [
        'Afghan',
        'Albanian',
        'Algerian',
        'American',
        'American Samoan',
        'Andorran',
        'Angolan',
        'Anguillan',
        'Antiguan',
        'Argentine',
        'Armenian',
        'Aruban',
        'Australian',
        'Austrian',
        'Azerbaijani',
        'Bahamian',
        'Bahraini',
        'Bangladeshi',
        'Barbadian',
        'Barbudan',
        'Basotho',
        'Belarusian',
        'Belgian',
        'Belizean',
        'Beninese',
        'Bermudian',
        'Bhutanese',
        'Bolivian',
        'Bosnian',
        'Brazilian',
        'British',
        'British Virgin Islander',
        'Bruneian',
        'Bulgarian',
        'Burkinabe',
        'Burmese',
        'Burundi',
        'Cambodian',
        'Cameroonian',
        'Canadian',
        'Cape Verdean',
        'Caymanian',
        'Central African',
        'Chadian',
        'Channel Islander',
        'Chilean',
        'Chinese',
        'Christmas Islander',
        'Chuukese',
        'Cocos Islander',
        'Colombian',
        'Comoran',
        'Congolese',
        'Cook Islander',
        'Costa Rican',
        'Croatian',
        'Cuban',
        'Cypriot',
        'Czech',
        'Danish',
        'Djiboutian',
        'Dominican',
        'Dutch',
        'Dutch Antillean',
        'Ecuadorian',
        'Egyptian',
        'Emirati',
        'Equatorial Guinean',
        'Eritrean',
        'Estonian',
        'Ethiopian',
        'Falkland Islander',
        'Faroese',
        'Fijian',
        'Finnish',
        'French',
        'French Guianese',
        'French Polynesian',
        'Gabonese',
        'Gambian',
        'Georgian',
        'German',
        'Ghanaian',
        'Gibraltar',
        'Greek',
        'Greenlandic',
        'Grenadian',
        'Guadeloupe',
        'Guamanian',
        'Guatemalan',
        'Guinean',
        'Guyanese',
        'Haitian',
        'Honduran',
        'Hong Kong (British/Chinese)',
        'Hungarian',
        'Icelandic',
        'I-Kiribati',
        'Indian',
        'Indonesian',
        'Iranian',
        'Iraqi',
        'Irish',
        'Israeli',
        'Italian',
        'Ivorian',
        'Jamaican',
        'Japanese',
        'Jordanian',
        'Kazakhstani',
        'Kenyan',
        'Kittitian',
        'Korean',
        'Kosrae',
        'Kuwaiti',
        'Kyrgyzstani',
        'Laotian',
        'Latin American',
        'Latvian',
        'Lebanese',
        'Liberian',
        'Libyan',
        'Liechtenstein',
        'Lithuanian',
        'Luxembourg',
        'Macedonian',
        'Mahoran',
        'Malagasy',
        'Malawian',
        'Malaysian',
        'Maldivian',
        'Malian',
        'Maltese',
        'Manx',
        'Marshallese',
        'Martiniquais',
        'Mauritanian',
        'Mauritian',
        'Mexican',
        'Micronesian',
        'Moldovan',
        'Monegasque',
        'Mongolian',
        'Montenegrin',
        'Montserratian',
        'Moroccan',
        'Motswana',
        'Mozambican',
        'Namibian',
        'Nauruan',
        'Nepalese',
        'New Caledonian',
        'New Zealand',
        'Nicaraguan',
        'Nigerian',
        'Nigerien',
        'Niuean',
        'Ni-Vanuatu',
        'Norfolk Islander',
        'Norwegian',
        'Omani',
        'Pakistani',
        'Palauan',
        'Panamanian',
        'Papua New Guinean',
        'Paraguayan',
        'Peruvian',
        'Philippine',
        'Pitcairn Islander',
        'Pohnpeian',
        'Polish',
        'Portuguese',
        'Puerto Rican',
        'Qatari',
        'Reunionese',
        'Romanian',
        'Russian',
        'Rwandan',
        'Sahrawian',
        'Saint Helenian',
        'Saint Lucian',
        'Saint Vincentian',
        'Salvadoran',
        'Sammarinese',
        'Samoan',
        'Sao Tomean',
        'Saudi Arabian',
        'Senegalese',
        'Serbian',
        'Seychellois',
        'Sierra Leonean',
        'Singapore',
        'Slovak',
        'Slovenian',
        'Solomon Islander',
        'Somali',
        'South African',
        'Spanish',
        'Sri Lankan',
        'Sudanese',
        'Surinamese',
        'Swazi',
        'Swedish',
        'Swiss',
        'Syrian',
        'Taiwanese',
        'Tajikistani',
        'Tanzanian',
        'Thai',
        'Timorese',
        'Tobagonian',
        'Togolese',
        'Tokelauan',
        'Tongan',
        'Trinidadian',
        'Trukese',
        'Tunisian',
        'Turkish',
        'Turkmen',
        'Tuvaluan',
        'Ugandan',
        'Ukrainian',
        'Uruguayan',
        'Uzbekistani',
        'Venezuelan',
        'Vietnamese',
        'Virgin Islander',
        'Wallis and Futuna Islander',
        'Yapese',
        'Yemeni',
        'Yugoslavian',
        'Zambian',
        'Zimbabwean',
        'Prefer not to say',
    ],
    disability: ['Yes', 'No', 'Prefer not to say'],
    religiousBelief: [
        'Atheism',
        'Christianity',
        'Buddhism',
        'Hinduism',
        'Islam',
        'Jainism',
        'Judaism',
        'Sikhism',
        'Other',
        'Prefer not to say',
    ],
    sexualOrientation: ['Lesbian', 'Gay', 'Bisexual', 'Heterosexual', 'Prefer not to say'],
};

export const specialityOptions = [
    'Cardiology',
    'Dermatology',
    'Diabetes',
    'Elderly',
    'ENT',
    'Gastroenterology',
    'Mental health',
    'MSK',
    'Neurology',
    'Ophthalmology',
    'Paediatrics',
    'Palliative',
    'Respiratory',
    'Urology',
    'Section 12(2) approved doctor (MHA)',
    "Woman's health/O&G",
];

export const languageOptions = [
    'Arabic',
    'Bengali',
    'Cantonese',
    'French',
    'German',
    'Gujarati',
    'Italian',
    'Lithuanian',
    'Mandarin',
    'Other Chinese',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Somali',
    'Spanish',
    'Tamil',
    'Turkish',
    'Urdu',
    'Welsh',
];

export const vaccinations = ['MMR', 'Hepatitis B', 'Tuberculosis', 'Varicella'];

export const covidStatuses = [
    'No test taken',
    'Antigen swab test - negative result',
    'Antigen swab test - postive result',
    'Antibody test - negative result',
    'Antibody test - positive result"',
];

export const trainingOptions = [
    {
        name: 'BLS Resuscitation',
        category: 'BLS_RESUSCITATION',
        value: 'documentationBLSResuscitation',
    },
    {
        name: 'Data Security Awareness',
        category: 'DATA_SECURITY_AWARENESS',
        value: 'documentationDataSecurityAwareness',
    },
    {
        name: 'Infection Control',
        category: 'INFECTION_CONTROL',
        value: 'documentationInfectionControl',
    },
    {
        name: 'Safeguarding Adults Level 1',
        category: 'SAFEGUARDING_ADULTS_1',
        value: 'documentationSafeguardingAdultsLevel1',
    },
    {
        name: 'Safeguarding Adults Level 2',
        category: 'SAFEGUARDING_ADULTS_2',
        value: 'documentationSafeguardingAdultsLevel2',
    },
    {
        name: 'Safeguarding Adults Level 3',
        category: 'SAFEGUARDING_ADULTS_3',
        value: 'documentationSafeguardingAdultsLevel3',
    },
    {
        name: 'Safeguarding Children Level 1',
        category: 'SAFEGUARDING_CHILDREN_1',
        value: 'documentationSafeguardingChildrenLevel1',
    },
    {
        name: 'Safeguarding Children Level 2',
        category: 'SAFEGUARDING_CHILDREN_2',
        value: 'documentationSafeguardingChildrenLevel2',
    },
    {
        name: 'Safeguarding Children Level 3',
        category: 'SAFEGUARDING_CHILDREN_3',
        value: 'documentationSafeguardingChildrenLevel3',
    },
    {
        name: 'Safeguarding Children Level 4',
        category: 'SAFEGUARDING_CHILDREN_4',
        value: 'documentationSafeguardingChildrenLevel4',
    },
    {
        name: 'P.R.E.V.E.N.T',
        category: 'PREVENT',
        value: 'documentationPREVENT',
    },
    {
        name: 'Moving and Handling',
        category: 'MOVING_AND_HANDLING',
        value: 'documentationMovingAndHandling',
    },
    {
        name: 'Health and Safety',
        category: 'HEALTH_AND_SAFETY',
        value: 'documentationHealthAndSafety',
    },
    {
        name: 'Fire Safety',
        category: 'FIRE_SAFETY',
        value: 'documentationFireSafety',
    },
    {
        name: 'Conflict Resolution',
        category: 'CONFICT_RESOLUTION',
        value: 'documentationConflictResolution',
    },
    {
        name: 'Mental Health Capacity Act',
        category: 'MENTAL_HEALTH_CAPACITY',
        value: 'documentationMentalHealthCapacity',
    },
    {
        name: 'Deprivation of Liberty',
        category: 'DEPRIVATION_OF_LIBERTY',
        value: 'documentationDeprivationOfLiberty',
    },
];

export const systemProficiencyOptions = ['ADASTRA', 'TPP SystmOne', 'EMISWeb', 'None', 'Other'];
