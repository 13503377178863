import React, { memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import equal from 'deep-equal';
import { ExclamationCircle } from 'heroicons-react';

import { pick } from '../../utils/shared';
import getInviteStyles from '../styles/inviteStyles';

const isOptional = {};

const checkOptional = async (id, isValid) => {
    if (isOptional[id] === undefined) {
        isOptional[id] = await isValid({});
    }

    return isOptional[id];
};

const valuesChanged = (prevProps, nextProps) => equal(prevProps.step.values, nextProps.step.values);

const Step = memo(({ step, index }) => {
    const { id, heading, isValid, values, callback } = step;

    const [valid, setValid] = useState();
    const [optional, setOptional] = useState();

    useEffect(() => {
        const checkIsValid = async () => {
            const optional = await checkOptional(id, isValid);
            setOptional(optional);
            if (optional === false) {
                const valid = await isValid(values);
                setValid(valid);
                callback({ id, valid });
            }
        };

        checkIsValid();
    });
    let label = '...';

    if (optional === false) {
        label = 'Incomplete';
    }

    if (optional) {
        label = 'Optional';
    }

    if (valid) {
        label = 'Complete';
    }

    const { bgClassName, textClassName700, borderClassName700 } = getInviteStyles(label);

    return (
        <li key={id}>
            <NavLink
                className="text-pink-700 flex bg-gray-200 rounded p-2 mb-1 border border-gray-300"
                activeStyle={{ color: '#4a5568' }}
                to={`/my-account/passport/onboarding/steps/${index + 1}`}
                onClick={() =>
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })
                }
            >
                <span className="w-4 text-center mr-4">{index + 1}</span>
                {heading}
                <span
                    className={`ml-auto flex items-center rounded text-xs px-1 ${bgClassName} ${textClassName700} border ${borderClassName700}`}
                >
                    {label}
                </span>
            </NavLink>
        </li>
    );
}, valuesChanged);

Step.displayName = 'Step';

const Steps = (props) => {
    const [validatedSections, setValidatedSections] = useState({});

    const callback = ({ id, valid }) =>
        setValidatedSections((validatedSections) => ({ ...validatedSections, [id]: valid }));

    const steps = props.steps.map((step) => {
        const { id, heading } = step.props;
        const validationSchema = props.validationSchemas[id];
        const fields = Object.keys(validationSchema.fields);

        return {
            values: pick(props.values, fields),
            isValid: validationSchema.isValid.bind(validationSchema),
            id,
            heading,
            callback,
        };
    });

    const sectionInvalid = props.touched && validatedSections[props.currentStep] === false;

    return (
        <>
            {sectionInvalid && (
                <div className="text-sm flex items-center text-red-700 mt-4 bg-red-100 rounded py-2 px-3 border border-red-700">
                    <span>
                        <ExclamationCircle className="mr-2" />
                    </span>
                    <span>Please fix the errors above before continuing.</span>
                </div>
            )}
            <ol className="mt-6 pt-6 border-t">
                {steps.map((step, index) => (
                    <Step step={step} index={index} key={index} />
                ))}
            </ol>
        </>
    );
};

export default Steps;
