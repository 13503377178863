import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';

const MFA = ({ didError, onConfirmCode }) => {
    const initialValues = {
        code: '',
    };

    const validationSchema = Yup.object({
        code: Yup.string()
            .matches(/^\d{6}$$/, 'Verification Code | Verification Code must be 6 digits only.')
            .required('Verification Code | This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onConfirmCode}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Multi-Factor Authentication
                        </p>

                        <p className="text-sm mb-6">
                            We've sent a verification code to your registered email address or phone
                            number, please enter it below to continue. The code will expire in three
                            minutes, if you do not enter it in time you must sign in again.
                        </p>

                        <Input
                            name="code"
                            type="text"
                            inputmode="numeric"
                            label="Verification Code"
                            placeholder="e.g. 123456"
                            disabled={isSubmitting}
                        />

                        <SubmitButton
                            isSubmitting={isSubmitting}
                            text="Confirm Verification Code"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MFA;
