import React from 'react';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
            <p className="text-2xl font-light mb-6">
                Doc Abode is a new way of working, delivering additional workforce capacity.
            </p>

            <p className="mb-12">
                The Doc Abode software platform supports NHS healthcare providers to deliver more
                responsive, cost-effective care by safely connecting and matching a
                multi-disciplinary clinical workforce to patient needs, in real-time, based on
                availability, proximity and expertise.
            </p>

            <Link className="block rounded bg-pink-700 text-white p-6 mb-4" to="/my-account">
                I <strong>already have an account</strong> on the Doc Abode platform
            </Link>
            <Link
                className="block rounded bg-blue-700 text-white p-6 mb-4"
                to="/register/individual"
            >
                I am a <strong>Healthcare Professional (HCP)</strong> and would like to join Doc
                Abode
            </Link>
            <Link className="block rounded bg-blue-700 text-white p-6" to="/register/organisation">
                I would like to register my <strong>organisation</strong> or{' '}
                <strong>practice</strong> with Doc Abode
            </Link>
        </>
    );
};

export default Intro;
