import React, { useCallback, useEffect, useState } from 'react';
import { ExclamationCircle } from 'heroicons-react';

import {
    AUTH_STATUS,
    completeNewPassword,
    confirmMFACode,
    forgotPassword,
    getSession,
    resetPassword,
    signIn,
    signOut,
    verifyCurrentUserAttribute,
    verifyCurrentUserAttributeSubmit,
} from '../../../utils/auth';
import api from '../../../utils/api';
import CompleteNewPassword from './CompleteNewPassword';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import MFA from './MFA';
import ResetPassword from './ResetPassword';
import VerifyContact from './VerifyContact';
import SignIn from './SignIn';
import Loading from '../../shared/Loading';
import { popDispatcher } from '../../shared/Pop';

export const SCREENS = {
    CHECKING: 'CHECKING',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_USERNAME: 'FORGOT_USERNAME',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SIGN_IN: 'SIGN_IN',
    MFA_ENTRY: 'MFA_ENTRY',
    COMPLETE_NEW_PASSWORD: 'COMPLETE_NEW_PASSWORD',
    VERIFY_CONTACT: 'VERIFY_CONTACT',
    LOGGED_IN: 'LOGGED_IN',
};

const Authenticator = ({ children }) => {
    const [currentScreen, setCurrentScreen] = useState(SCREENS.CHECKING);
    const [forgottenPasswordUsername, setForgottenPasswordUsername] = useState(null);
    const [error, setError] = useState('');
    const [signInResult, setSignInResult] = useState(null);

    const changeScreen = useCallback((nextScreen) => {
        setCurrentScreen(nextScreen);
        setError('');
    }, []);

    const handleError = (message) =>
        setError(
            message ||
                'Sorry, we were unable to sign you in. Please check your details and try again.',
        );

    const onToggleForgotPassword = () => {
        const nextScreen =
            currentScreen === SCREENS.FORGOT_PASSWORD ? SCREENS.SIGN_IN : SCREENS.FORGOT_PASSWORD;

        changeScreen(nextScreen);
    };

    const onToggleForgotUsername = () => {
        const nextScreen =
            currentScreen === SCREENS.FORGOT_USERNAME ? SCREENS.SIGN_IN : SCREENS.FORGOT_USERNAME;

        changeScreen(nextScreen);
    };

    useEffect(() => {
        const checkSession = async () => {
            const session = await getSession();
            setSignInResult(session);

            if (!session) {
                return changeScreen(SCREENS.SIGN_IN);
            }

            const verifiedContact = session.emailVerified || session.phoneNumberVerified;

            if (verifiedContact) {
                setCurrentScreen(SCREENS.LOGGED_IN);
            } else {
                setCurrentScreen(SCREENS.VERIFY_CONTACT);
            }
        };
        setTimeout(() => {
            checkSession();
        }, 500);
    }, [changeScreen]);

    const onSignIn = async (values, { setSubmitting }) => {
        setError('');

        try {
            const result = await signIn(values.userName, values.password);

            setSubmitting(false);

            if (result.status === 'SMS_MFA' || result.status === 'SOFTWARE_TOKEN_MFA') {
                setSignInResult(result);
                changeScreen(SCREENS.MFA_ENTRY);
            } else if (result.status === AUTH_STATUS.VERIFICATION_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.VERIFY_CONTACT);
            } else if (result.status === AUTH_STATUS.NEW_PASSWORD_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.COMPLETE_NEW_PASSWORD);
            } else {
                const session = await getSession();
                setSignInResult(session);
                changeScreen(SCREENS.LOGGED_IN);
            }
        } catch (error) {
            handleError(
                /password has expired/.test(error.message) ? (
                    <>
                        Sorry, your temporary password has expired and must be reset by an
                        administrator.
                        <br />
                        <br />
                        Please email us at{' '}
                        <a className="text-pink-700 underline" href="mailto:support@docabode.com">
                            support@docabode.com
                        </a>{' '}
                        and include your username so that we can create you a new password.
                    </>
                ) : error.message === 'User does not exist.' &&
                  (values.userName.includes('.admin') ||
                      values.userName.includes('.controller')) ? (
                    <>
                        It looks like you might be trying to sign in using an admin or controller
                        account. If that is the case, please log in using those credentials at{' '}
                        <a
                            className="text-pink-700 underline"
                            href="https://controller.docabode.com"
                        >
                            controller.docabode.com
                        </a>{' '}
                        instead.
                    </>
                ) : (
                    error.message
                ),
            );
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onCompleteNewPassword = async (values, { setSubmitting }) => {
        setError('');

        try {
            const result = await completeNewPassword(signInResult.user, values.newPassword);

            setSubmitting(false);

            if (result.status === AUTH_STATUS.VERIFICATION_REQUIRED) {
                setSignInResult(result);
                changeScreen(SCREENS.VERIFY_CONTACT);
            } else {
                const session = await getSession();
                setSignInResult(session);
                changeScreen(SCREENS.LOGGED_IN);
            }
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onConfirmCode = async ({ code }, { setSubmitting }) => {
        setError('');

        try {
            await confirmMFACode(signInResult.user, code, signInResult.status);
            const session = await getSession();
            setSubmitting(false);
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(
                error.code === 'CodeMismatchException'
                    ? 'The code you have entered is invalid, please try again.'
                    : error.message,
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onForgotPassword = async (values, { setSubmitting }) => {
        setError('');

        try {
            await forgotPassword(values.userName);
            setSubmitting(false);
            setForgottenPasswordUsername(values.userName);
            changeScreen(SCREENS.RESET_PASSWORD);
        } catch (error) {
            handleError(
                error.code === 'InvalidParameterException' ? (
                    <>
                        Sorry, you cannot reset your password as you do not have a verified email
                        address or phone number for us to send your new password. Please email us at{' '}
                        <a className="text-pink-700 underline" href="mailto:support@docabode.com">
                            support@docabode.com
                        </a>{' '}
                        and include your username so that we can create you a new password.
                    </>
                ) : (
                    error.message
                ),
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onForgotUsername = async (values, { setSubmitting }) => {
        setError('');

        try {
            await api.forgottenUsername(values);
            setSubmitting(false);
            changeScreen(SCREENS.SIGN_IN);
            popDispatcher.addPop({ type: 'success', message: 'Email sent!' });
        } catch (error) {
            handleError(
                error.response?.status === 404
                    ? 'Sorry, no user was found with that email address. Please check it and try again.'
                    : error.message,
            );
            console.error(error);
            setSubmitting(false);
        }
    };

    const onResetPassword = async ({ code, newPassword }, { setSubmitting }) => {
        setError('');

        try {
            await resetPassword(forgottenPasswordUsername, code, newPassword);
            const session = await getSession();
            setSubmitting(false);
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSubmitting(false);
            setSignInResult(null);
        }
    };

    const onVerifyContact = async ({ contactType, code }, { setSubmitting }) => {
        setError('');

        try {
            await verifyCurrentUserAttributeSubmit(contactType, String(code));
            const session = await getSession();
            setSignInResult(session);
            changeScreen(SCREENS.LOGGED_IN);
        } catch (error) {
            handleError(error.message);
            console.error(error);
            setSignInResult(null);
        }

        setSubmitting(false);
    };

    const onRequestCode = async (contactType) => {
        setError('');

        try {
            await verifyCurrentUserAttribute(contactType);
        } catch (error) {
            handleError(error.message);
            console.error(error);
        }
    };

    const onResendCode = async () => {
        setError('');

        try {
            await forgotPassword(forgottenPasswordUsername);
        } catch (error) {
            handleError(error.message);
            console.error(error);
        }
    };

    const onSignOut = async () => {
        setError('');

        try {
            await signOut();
            setSignInResult(null);
        } catch (error) {
            handleError(error.message);
            console.error(error);
        }
    };

    const getComponent = () => {
        if (currentScreen === SCREENS.CHECKING) {
            return (
                <div>
                    <Loading />
                    <p className="text-sm">Checking existing session, please wait...</p>
                </div>
            );
        }

        if (currentScreen === SCREENS.FORGOT_PASSWORD) {
            return (
                <ForgotPassword
                    onBackToSignIn={() => {
                        changeScreen(SCREENS.SIGN_IN);
                    }}
                    onForgotPassword={onForgotPassword}
                />
            );
        }

        if (currentScreen === SCREENS.FORGOT_USERNAME) {
            return (
                <ForgotUsername
                    onBackToSignIn={() => {
                        setError(null);
                        changeScreen(SCREENS.SIGN_IN);
                    }}
                    onForgotUsername={onForgotUsername}
                />
            );
        }

        if (currentScreen === SCREENS.RESET_PASSWORD) {
            return <ResetPassword onResendCode={onResendCode} onResetPassword={onResetPassword} />;
        }

        if (currentScreen === SCREENS.COMPLETE_NEW_PASSWORD) {
            return <CompleteNewPassword onCompleteNewPassword={onCompleteNewPassword} />;
        }

        if (currentScreen === SCREENS.MFA_ENTRY) {
            return <MFA onConfirmCode={onConfirmCode} />;
        }

        if (currentScreen === SCREENS.VERIFY_CONTACT) {
            return (
                <VerifyContact onRequestCode={onRequestCode} onVerifyContact={onVerifyContact} />
            );
        }

        if (currentScreen === SCREENS.LOGGED_IN && signInResult) {
            return (
                <div>
                    {children}
                    <p className="mt-4 pt-4 border-t text-sm mt-4">
                        Download the Doc Abode mobile app
                    </p>
                    <div className="flex justify-center h-16">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.docabode.hcp.app&hl=en_GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="h-full block"
                        >
                            <img
                                alt="Get it on Google Play"
                                src="/google-play-badge.png"
                                className="h-full"
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/vg/app/doc-abode/id1448705483"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="h-full block"
                        >
                            <img
                                alt="Download on the App Store"
                                src="/app-store-badge.svg"
                                className="h-full"
                            />
                        </a>
                    </div>
                    <p className="text-sm mt-4">
                        Signed in as {signInResult.username} (
                        <button className="text-pink-700 underline" onClick={onSignOut}>
                            Sign out
                        </button>
                        )
                    </p>
                </div>
            );
        }

        // Default to show sign in
        return (
            <SignIn
                onSignIn={onSignIn}
                onToggleForgotPassword={onToggleForgotPassword}
                onToggleForgotUsername={onToggleForgotUsername}
            />
        );
    };

    return (
        <>
            {error && (
                <div className="text-sm text-red-700 rounded py-2 px-4 mb-6 bg-red-100 border border-red-600 text-left flex">
                    <span>
                        <ExclamationCircle className="mr-2" />
                    </span>
                    <p className="leading-6">{error}</p>
                </div>
            )}
            {getComponent()}
            {currentScreen !== SCREENS.SIGN_IN && currentScreen !== SCREENS.LOGGED_IN && (
                <p className="text-sm mt-6 text-center">
                    <button
                        type="button"
                        className="text-pink-700 underline"
                        onClick={() => changeScreen(SCREENS.SIGN_IN)}
                    >
                        Back to Sign In
                    </button>
                </p>
            )}
        </>
    );
};

export default Authenticator;
