import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import OrganisationList from './OrganisationList';
import Organisation from './Organisation';
import Loading from '../../shared/Loading';
import api from '../../../utils/api';

const AllOrganisations = ({ invites, loading, setInvites, onboarding }) => {
    const [didError, setDidError] = useState('');
    const [isFetching, setIsFetching] = useState(true);
    const [organisations, setOrganisations] = useState([]);
    const [user, setUser] = useState(null);

    const { path } = useRouteMatch();

    const onLoadData = async () => {
        setDidError(false);
        setIsFetching(true);

        try {
            const userResponse = await api.getUser();
            setUser(userResponse.data);
        } catch (err) {
            console.error(err);
            setDidError('FETCH_USER_ERROR');
        }

        try {
            const organisationsList = await api.getAvailableOrganisations();
            setOrganisations(organisationsList);
        } catch (err) {
            console.error(err);
            setDidError('FETCH_ORGANISATIONS_ERROR');
        }

        setIsFetching(false);
    };

    useEffect(() => {
        onLoadData();
    }, []);

    if (isFetching || loading) {
        return (
            <>
                <Loading />
                <p className="text-sm">Loading list of organisations, please wait...</p>
            </>
        );
    }

    if (didError === 'FETCH_ORGANISATIONS_ERROR') {
        return (
            <p className="text-sm">
                Unable to retrieve list of available organisations.{' '}
                <button type="button" className="text-pink-700 underline" onClick={onLoadData}>
                    Try again?
                </button>{' '}
            </p>
        );
    }

    return (
        <Switch>
            <Route path={`${path}/:organisation`}>
                <Organisation
                    organisations={organisations}
                    invites={invites}
                    user={user}
                    setInvites={setInvites}
                    onboarding={onboarding}
                />
            </Route>
            <Route>
                <OrganisationList
                    organisations={organisations}
                    invites={invites}
                    onboarding={onboarding}
                />
            </Route>
        </Switch>
    );
};

export default AllOrganisations;
