import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory, Link } from 'react-router-dom';

import Progress from './Progress';
import Steps from './Steps';
import SubmitButton from '../shared/forms/SubmitButton';

const Wizard = ({
    children,
    completeText,
    initialValues,
    onSubmit,
    didError,
    setDidError,
    validationSchemas,
    invalidSections,
    errorText,
    stepNumber,
    intro,
}) => {
    const history = useHistory();
    const steps = React.Children.toArray(children);
    const [snapshot, setSnapshot] = useState(initialValues);

    const CurrentStep = steps[stepNumber - 1];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps;

    const onNext = (values) => {
        setSnapshot(values);
        setDidError(false);
        history.push(
            `/my-account/passport/onboarding/steps/${Math.min(stepNumber + 1, totalSteps)}`,
        );

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onPrevious = (values) => {
        setSnapshot(values);
        setDidError(false);
        history.push(`/my-account/passport/onboarding/steps/${Math.max(stepNumber - 1, 0)}`);

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleSubmit = async (values, bag) => {
        if (CurrentStep.props.onSubmit) {
            try {
                await CurrentStep.props.onSubmit(values, bag);
            } catch (err) {
                console.error(err);
            }
        }

        if (isLastStep) {
            return onSubmit(values, bag);
        }

        bag.setTouched({});
        onNext(values);
    };

    return (
        <Formik
            initialValues={snapshot}
            onSubmit={handleSubmit}
            validationSchema={validationSchemas[CurrentStep?.props?.id || {}]}
        >
            {({ values, isSubmitting, touched }) => {
                return (
                    <Form className="text-left">
                        {stepNumber === 0 ? (
                            intro
                        ) : (
                            <>
                                <p className="text-sm text-center mb-1">
                                    Step {stepNumber} of {totalSteps}
                                </p>

                                <Progress percent={(100 / totalSteps) * stepNumber} />

                                {React.cloneElement(CurrentStep, { values })}

                                <div className="pt-2">
                                    {didError && isLastStep && (
                                        <p className="text-sm text-center text-red-700 mb-4">
                                            We were unable to process your application. Please try
                                            again.
                                        </p>
                                    )}
                                    {invalidSections.length > 0 && isLastStep && (
                                        <>
                                            <p className="text-sm text-center text-red-700 mb-2">
                                                The following sections are invalid:{' '}
                                                {invalidSections
                                                    .map(
                                                        (section) =>
                                                            steps.find(
                                                                (step) => step.props.id === section,
                                                            ).props.heading,
                                                    )
                                                    .join(', ')}
                                                .
                                            </p>
                                            <p className="text-sm text-center text-red-700 mb-4">
                                                Please check the form and try again.
                                            </p>
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="flex justify-between">
                            {stepNumber > 1 && (
                                <button
                                    className="block rounded py-2 px-6 bg-gray-200 text-gray-700"
                                    onClick={() => onPrevious(values)}
                                    type="button"
                                >
                                    Back
                                </button>
                            )}

                            {stepNumber > 0 ? (
                                <>
                                    <Link
                                        className="block rounded py-2 px-6 bg-gray-200 text-gray-700"
                                        to="/my-account/passport/onboarding/steps"
                                    >
                                        Overview
                                    </Link>

                                    <SubmitButton
                                        isSubmitting={isSubmitting}
                                        text={isLastStep ? completeText : 'Save and continue'}
                                        small
                                    />
                                </>
                            ) : (
                                <Link
                                    className="block rounded bg-pink-700 text-white py-2 px-6 ml-auto mr-auto"
                                    to="/my-account/passport/onboarding/steps/1"
                                >
                                    Get started
                                </Link>
                            )}
                        </div>

                        <Steps
                            steps={steps}
                            values={values}
                            validationSchemas={validationSchemas}
                            currentStep={CurrentStep?.props.id}
                            touched={Object.keys(touched).length > 0}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export const WizardStep = ({ children, heading, values }) => {
    return (
        <div className="mt-2">
            <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                {heading}
            </p>
            {children(values)}
        </div>
    );
};

export default Wizard;
