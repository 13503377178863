import React from 'react';
import { Link } from 'react-router-dom';
import { InformationCircle } from 'heroicons-react';

import OrganisationLink from './OrganisationLink';

const sortOrganisations = (a, b) => {
    if (a.name > b.name) {
        return 1;
    }
    return -1;
};

const OrganisationList = ({ invites, organisations, onboarding }) => {
    const filteredOrgansiations = organisations.filter(
        ({ acceptingUserInvitations, features }) => acceptingUserInvitations && features.invites,
    );

    return (
        <>
            <h1 className="text-2xl font-light text-pink-700 mb-4">All Organisations</h1>
            <Link
                className="text-sm mx-auto rounded py-2 px-6 bg-gray-200 text-gray-700 mb-6 inline-block"
                to="/my-account/organisations"
            >
                Return to My Organisations
            </Link>
            {onboarding.attributes.onboardingStatus !== 'COMPLETE' && (
                <div className="text-sm text-blue-700 rounded py-2 px-4 mb-4 bg-blue-100 border border-blue-600 text-left flex">
                    <span>
                        <InformationCircle className="mr-4" />
                    </span>
                    <div>
                        <p className="mb-2">
                            You must complete the{' '}
                            <Link
                                className="text-pink-700 underline"
                                to="/my-account/passport/onboarding/steps/"
                            >
                                onboarding process
                            </Link>{' '}
                            before you can apply to join any new organsiations.
                        </p>
                        <p className="mb-2">
                            However, if you are already assured to work for an organisation you may
                            still be able to make a fast track application to them.
                        </p>
                    </div>
                </div>
            )}
            <p className="text-sm text-left mb-2">
                These are the organisations on Doc Abode that are currently accepting applications
                from clinicians. Once the organisation approves your application you will receive
                notifications of any available jobs. You can choose to join more than one
                organisation.
            </p>
            <p className="text-sm text-left mb-5">
                Most applications are reviewed and approved within 14 days of submission. If your
                application is incomplete or missing any information, we will be in touch directly.
            </p>
            {filteredOrgansiations.sort(sortOrganisations).map(({ org, name }) => (
                <OrganisationLink
                    org={org}
                    name={name}
                    invite={invites.find((invite) => invite.organisation === org)}
                    key={org}
                />
            ))}
        </>
    );
};

export default OrganisationList;
