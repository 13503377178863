import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
    CheckCircle,
    ExclamationCircle,
    MinusCircle,
    ChatAlt,
    DocumentDownload,
    ExternalLink,
    InformationCircle,
} from 'heroicons-react';

import SubmitButton from '../../shared/forms/SubmitButton';
import Loading from '../../shared/Loading';
import api from '../../../utils/api';
import { prettyString, prettyDateTime } from '../../../utils/shared';
import getInviteStyles from '../../styles/inviteStyles';

const Organisation = ({ organisations, invites, user, setInvites, onboarding }) => {
    const [didError, setDidError] = useState(false);
    const [didFail, setDidFail] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didComplete, setDidComplete] = useState(false);
    const [fastTrackApplication, setFastTrackApplication] = useState(false);
    const [downloads, setDownloads] = useState({});
    const [organisationOnboarding, setOrganisationOnboarding] = useState(null);

    const { organisation } = useParams();
    const { state: locationState } = useLocation();

    const { onboardingId, name: organisationName, features } = organisations.find(
        ({ org }) => org === organisation,
    );
    const [invite] = invites.filter((invite) => invite.organisation === organisation);

    const onLoadOnboarding = useCallback(async () => {
        setDidError(false);
        setIsFetching(true);

        try {
            const onboardingResponse = await api.getOrganisationOnboarding(onboardingId);
            setOrganisationOnboarding(onboardingResponse);
        } catch (err) {
            console.error(err);
            setDidError(true);
        }

        setIsFetching(false);
    }, [onboardingId]);

    useEffect(() => {
        onLoadOnboarding();
    }, [onLoadOnboarding]);

    const downloadFile = async (key) => {
        setDownloads({ ...downloads, [key]: true });
        const url = await api.downloadOrganisationFile(key);
        setDownloads({ ...downloads, [key]: false });
        window.open(url);
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        setDidFail(false);

        const {
            attributes: { onboardingId },
            values: { name: userName },
        } = onboarding;

        try {
            await api.submitInvite({ organisation, organisationName, onboardingId, userName });
            const currentInvites = await api.getInvites();

            setDidComplete(true);
            setInvites(currentInvites);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (err) {
            console.error(err);
            setDidFail(true);
        }

        setIsSubmitting(false);
    };

    if (isFetching) {
        return (
            <>
                <Loading />
                <p className="text-sm">Loading organisation details, please wait...</p>
            </>
        );
    }

    const {
        leadContactEmail,
        leadContactName,
        leadContactPhoneNumber,
        organisationAddressLine1,
        organisationAddressLine2,
        organisationCounty,
        organisationPostCode,
        organisationTown,
        organisationODSCode,
        website,
        files = [],
        links = [],
    } = organisationOnboarding || {};

    const {
        bgClassName,
        textClassName500,
        textClassName700,
        borderClassName500,
        borderClassName700,
    } = getInviteStyles(invite?.inviteStatus || '');

    const orgData = user?.organisations?.[organisation];

    const allowApplication =
        onboarding.attributes.onboardingStatus === 'COMPLETE' || fastTrackApplication;

    return (
        <>
            <h1 className="text-2xl font-light text-pink-700 mb-4 flex items-center justify-center">
                {organisationName}
                {invite && (
                    <span
                        className={`inline-block rounded text-sm ml-4 px-2 py-1 ${bgClassName} ${textClassName700} border ${borderClassName700} capitalize`}
                    >
                        {prettyString(invite.inviteStatus)}
                    </span>
                )}
            </h1>
            <Link
                className="text-sm mx-auto rounded py-2 px-6 bg-gray-200 text-gray-700"
                to="/my-account/organisations/all"
            >
                Return to All Organisations
            </Link>
            <div className="text-left my-8">
                {!organisationOnboarding && <Loading />}
                {didComplete && (
                    <div className="text-sm text-green-700 rounded py-2 px-4 mb-4 bg-green-100 border border-green-600 text-left flex items-center">
                        <span>
                            <CheckCircle className="mr-4" />
                        </span>
                        <span>
                            Your application to {organisationName} has been submitted! You can track
                            its progress in the{' '}
                            <Link
                                className="text-pink-700 underline"
                                to="/my-account/organisations/"
                            >
                                My Organisations
                            </Link>{' '}
                            section.
                        </span>
                    </div>
                )}
                {locationState?.redeemSuccess && (
                    <div className="text-sm text-green-700 rounded py-2 px-4 mb-4 bg-green-100 border border-green-600 text-left flex items-center">
                        <span>
                            <CheckCircle className="mr-4" />
                        </span>
                        <span>Your invitation to {organisationName} has been redeemed!</span>
                    </div>
                )}
                {!invite && onboarding.attributes.onboardingStatus !== 'COMPLETE' && (
                    <div className="text-sm text-blue-700 rounded py-2 px-4 mb-4 bg-blue-100 border border-blue-600 text-left flex">
                        <span>
                            <InformationCircle className="mr-4" />
                        </span>
                        <div>
                            <p className="mb-2">
                                You must complete the{' '}
                                <Link
                                    className="text-pink-700 underline"
                                    to="/my-account/passport/onboarding/steps/"
                                >
                                    onboarding process
                                </Link>{' '}
                                before you can apply to join any new organsiations.
                            </p>
                            <p className="mb-2">
                                However, if you are already assured to work for this organisation
                                you may be able to make a fast track application.
                            </p>
                            <label className="flex items-center cursor-pointer">
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    checked={fastTrackApplication}
                                    onChange={(event) =>
                                        setFastTrackApplication(event.target.checked)
                                    }
                                />
                                I already work for this organisation
                            </label>
                        </div>
                    </div>
                )}
                {invite?.inviteStatus !== 'APPROVED' && invite?.comments?.length > 0 && (
                    <>
                        <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 mb-4">
                            Comments on Your Application
                        </h2>
                        {invite.comments.map(({ message, author, timestamp }) => (
                            <div
                                className={`text-sm mt-2 pb-2 px-3 bg-white rounded border ${borderClassName500} flex`}
                                key={timestamp}
                            >
                                <span className={`${textClassName500} pt-2`}>
                                    <ChatAlt />
                                </span>
                                <div className="pl-3">
                                    <p className="mt-2 mb-2 text-gray-600">
                                        From {author} on {prettyDateTime(timestamp)}:
                                    </p>
                                    <p>{message}</p>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {invite?.inviteStatus === 'APPROVED' && (
                    <>
                        <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 mb-4">
                            My {organisationName}
                        </h2>
                        {features.secureComms && (
                            <>
                                <h3 className="text-l font-light text-pink-700 mt-5 mb-2">
                                    Secure Communications Portal
                                </h3>
                                <p className="text-sm mb-2">
                                    Click below to log into the secure telephone and video
                                    consultation platform on behalf of {organisationName}.
                                </p>
                                <p className="text-sm mb-2">
                                    <strong>
                                        Only calls relating to jobs posted on Doc Abode by{' '}
                                        {organisationName} should be made via this link.
                                    </strong>
                                </p>
                                <Link
                                    to={`/transfer/org/${organisation}`}
                                    className="inline-block rounded bg-pink-700 text-white px-6 py-2 mt-2 inline-flex items-center"
                                    target="_blank"
                                >
                                    <ExternalLink className="mr-2" />
                                    Launch Secure Communications Portal
                                </Link>
                            </>
                        )}
                        <h3 className="text-l font-light text-pink-700 mt-5 mb-2">Availability</h3>
                        <p className="text-sm mb-2">
                            This determines whether or not you will be notified of new jobs posted
                            by {organisationName} and can be managed via the Doc Abode mobile app.
                            If you are set to unavailable you can still view and pick up jobs.
                        </p>
                        {orgData.available === true ? (
                            <span className="text-green-700 flex items-center text-sm">
                                <CheckCircle className="mr-1" /> <strong>Available</strong>
                            </span>
                        ) : (
                            <span className="text-gray-700 flex items-center text-sm">
                                <MinusCircle className="mr-1" /> <strong>Unavailable</strong>
                            </span>
                        )}
                        <h3 className="text-l font-light text-pink-700 mt-5 mb-2">Status</h3>
                        <p className="text-sm mb-2">
                            This determines whether or not you can see jobs posted by{' '}
                            {organisationName} and is managed by them.{' '}
                            {orgData.enabled === false && (
                                <>
                                    If you are unsure why you are disabled, please contact them
                                    directly.
                                </>
                            )}
                        </p>
                        {orgData.enabled === true ? (
                            <span className="text-green-700 flex items-center text-sm">
                                <CheckCircle className="mr-1" /> <strong>Enabled</strong>
                            </span>
                        ) : orgData.enabled === false ? (
                            <span className="text-red-700 flex items-center text-sm">
                                <MinusCircle className="mr-1" /> <strong>Disabled</strong>
                            </span>
                        ) : (
                            <span className="text-orange-700 flex items-center text-sm">
                                <ExclamationCircle className="mr-1" />{' '}
                                <strong>
                                    There is a problem with your configuration for this organisation
                                </strong>
                            </span>
                        )}
                    </>
                )}
                {didError && (
                    <p className="text-sm">
                        Unable to retrieve organisation details.{' '}
                        <button
                            type="button"
                            className="text-pink-700 underline"
                            onClick={onLoadOnboarding}
                        >
                            Try again?
                        </button>
                    </p>
                )}
                {organisationOnboarding && (
                    <>
                        <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 my-4">
                            Information
                        </h2>
                        {organisationAddressLine1 && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">Address</p>
                                <div className="text-sm">
                                    <p>{organisationAddressLine1}</p>
                                    <p>{organisationAddressLine2}</p>
                                    <p>{organisationTown}</p>
                                    <p>{organisationCounty}</p>
                                    <p>{organisationPostCode}</p>
                                </div>
                            </>
                        )}
                        {organisationODSCode && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">
                                    ODS Code
                                </p>
                                <p className="text-sm">{organisationODSCode}</p>
                            </>
                        )}
                        {(leadContactName || leadContactEmail || leadContactPhoneNumber) && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">
                                    Lead contact details
                                </p>
                                <dl className="text-sm">
                                    {leadContactName && (
                                        <>
                                            <dt className="font-bold">Name</dt>
                                            <dd className="mb-2">{leadContactName}</dd>
                                        </>
                                    )}
                                    {leadContactEmail && (
                                        <>
                                            <dt className="font-bold">Email address</dt>
                                            <dd className="mb-2">
                                                <a
                                                    className="text-pink-700 underline"
                                                    href={`mailto:${leadContactEmail}`}
                                                >
                                                    {leadContactEmail}
                                                </a>
                                            </dd>
                                        </>
                                    )}
                                    {leadContactPhoneNumber && (
                                        <>
                                            <dt className="font-bold">Phone number</dt>
                                            <dd className="mb-2">{leadContactPhoneNumber}</dd>
                                        </>
                                    )}
                                </dl>
                            </>
                        )}
                        {website && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">Website</p>
                                <p className="text-sm">
                                    <a
                                        className="text-pink-700 underline"
                                        href={website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {website}
                                    </a>
                                </p>
                            </>
                        )}
                        {(files.length > 0 || links.length > 0) && (
                            <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 my-4">
                                Assets
                            </h2>
                        )}
                        {files.length > 0 && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">Files</p>
                                {files.map(({ key, fileName }) => (
                                    <div
                                        className="flex items-center bg-gray-200 text-sm rounded p-2 mb-2 border cursor-pointer text-pink-700"
                                        key={key}
                                        onClick={() => downloadFile(key)}
                                    >
                                        <DocumentDownload className="mr-2" /> {fileName}
                                    </div>
                                ))}
                            </>
                        )}
                        {links.length > 0 && (
                            <>
                                <p className="text-l font-light text-pink-700 mt-5 mb-2">Links</p>
                                {links.map(({ title, url }, index) => (
                                    <a
                                        className="flex items-center bg-gray-200 text-sm rounded p-2 mb-2 border cursor-pointer text-pink-700"
                                        key={`${url}-${index}`}
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ExternalLink className="mr-2" /> {title}
                                    </a>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
            {!invite && allowApplication && (
                <SubmitButton
                    className="mb-4"
                    onSubmit={onSubmit}
                    text={`Request to join ${organisationName}`}
                    isSubmitting={isSubmitting}
                />
            )}
            {didFail && (
                <p className="text-sm text-center text-red-700 mb-4">
                    Sorry, we were unable to submit your request. Please try again.
                </p>
            )}
        </>
    );
};

export default Organisation;
