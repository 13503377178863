import { CheckCircle } from 'heroicons-react';
import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

import Loading from '../../shared/Loading';
import PassportSummary from './PassportSummary';

const MyPassport = ({ onboarding, loading }) => {
    const { path } = useRouteMatch();
    const query = new URLSearchParams(useLocation().search);

    if (loading) {
        return (
            <div>
                <Loading />
                <p className="text-sm">Loading passport, please wait...</p>
            </div>
        );
    }

    const { onboardingStatus } = onboarding.attributes;

    return (
        <>
            <h1 className="text-2xl font-light text-pink-700 mb-4">My Passport</h1>
            {query.get('onboardingComplete') && (
                <div className="text-sm text-green-700 rounded py-2 px-4 mb-4 bg-green-100 border border-green-600 text-left flex items-center">
                    <span>
                        <CheckCircle className="mr-4" />
                    </span>
                    <span>
                        Congratulations, your onboarding process is complete! You can now apply to
                        work for new organisations from the{' '}
                        <Link className="text-pink-700 underline" to="/my-account/organisations/">
                            My Organisations
                        </Link>{' '}
                        section.
                    </span>
                </div>
            )}
            {onboardingStatus === 'COMPLETE' ? (
                <>
                    <Link
                        className="inline-block rounded bg-pink-700 text-white px-6 py-2 mb-4"
                        to={`${path}/onboarding/steps`}
                    >
                        Edit Passport
                    </Link>
                    <PassportSummary onboarding={onboarding} />
                </>
            ) : (
                <>
                    <p className="mb-4 mx-12">
                        It looks like you haven't finished your onboarding process yet. Once that is
                        complete you can return here to view your passport.
                    </p>
                    <Link
                        className="inline-block rounded bg-pink-700 text-white px-6 py-2 mb-4"
                        to={`${path}/onboarding/steps`}
                    >
                        Continue Onboarding
                    </Link>
                </>
            )}
        </>
    );
};

export default MyPassport;
