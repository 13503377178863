import React, { useState, useEffect, useCallback, Fragment } from 'react';

const UserOnboardingDetails = ({ onboarding }) => {
    const [summary, setSummary] = useState({});

    const getSummary = useCallback(async () => {
        const summary = await onboarding.summary();
        setSummary(summary);
    }, [onboarding]);

    useEffect(() => {
        getSummary();
    }, [getSummary]);

    const { onboardingLite } = onboarding.attributes;

    return (
        <div className="bg-gray-100 px-6 py-3 text-left">
            {Object.values(summary).map(({ title, lite, items, validation }) => {
                if (onboardingLite && !lite) return null;
                return (
                    <Fragment key={title}>
                        <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 mb-4 text-left">
                            {title}
                        </h2>
                        <dl className="flex flex-col sm:flex-row sm:flex-wrap text-sm">
                            {Object.values(items).map(({ title, lite, value }) => {
                                if (!value || (onboardingLite && !lite)) return null;
                                return (
                                    <Fragment key={title}>
                                        <dt className="sm:w-1/2 font-bold py-1">{title}</dt>
                                        <dd className="sm:w-1/2 pl-3 py-1">{value}</dd>
                                    </Fragment>
                                );
                            })}
                        </dl>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default UserOnboardingDetails;
