import React, { useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { OnboardingUser } from '@doc-abode/data-models';

import api from '../../../utils/api';
import { sortAlpha } from '../../../utils/shared';
import { OnboardingUserContext } from '../../../utils/context';
import Loading from '../../shared/Loading';
import Checkbox from '../../shared/forms/Checkbox';
import Input from '../../shared/forms/Input';
import FileInput from '../../shared/forms/FileInput';
import Radio from '../../shared/forms/Radio';
import Select from '../../shared/forms/Select';
import TextArea from '../../shared/forms/TextArea';
import Wizard, { WizardStep } from '../../shared/Wizard';
import { popDispatcher } from '../../shared/Pop';

const {
    hcpTypes,
    trainingOptions,
    professionalBodies,
    deviceOptions,
    equalityOptions,
    specialityOptions,
    languageOptions,
    primaryGPFunctions,
    vaccinations,
    covidStatuses,
    systemProficiencyOptions,
    yesNoOptions,
    tradingStatusOptions,
    pensionTierDeductionOptions,
} = OnboardingUser.lookups;

const professionalBodyOptions = Object.entries(professionalBodies).map(
    ([value, { name, order }]) => ({
        value,
        name,
        order,
    }),
);

const OnboardingWizard = ({ onboarding, loading }) => {
    const [didComplete, setDidComplete] = useState(false);
    const [didError, setDidError] = useState(false);
    const [invalidSections, setInvalidSections] = useState([]);

    const { step } = useParams();
    const history = useHistory();

    if (loading) {
        return (
            <div>
                <Loading />
                <p className="text-sm">Loading onboarding details, please wait...</p>
            </div>
        );
    }

    const {
        onboardingId,
        onboardingExistingUser,
        onboardingStatus,
        onboardingLite,
    } = onboarding.attributes;

    const onboardingComplete = onboardingStatus === 'COMPLETE';

    const showSuccessPop = () =>
        popDispatcher.addPop({ type: 'success', message: 'Progress saved!' });

    const showErrorPop = (message) =>
        popDispatcher.addPop({ type: 'error', message: `Could not save progress: ${message}` });

    const onSubmitStep = (values) => {
        onboarding.update(values);
        api.saveOnboarding(onboarding.export(), onboardingId, showSuccessPop, showErrorPop);
    };

    const onSubmit = async (values, { setSubmitting }) => {
        onboarding.update(values);

        const validatedSections = await onboarding.validateSections();

        const invalidSections = Object.entries(validatedSections)
            .filter(([section, result]) => !result.isValid)
            .map(([section]) => section);

        if (invalidSections.length > 0) {
            return setInvalidSections(invalidSections);
        }

        try {
            setSubmitting(true);
            setInvalidSections([]);

            const completedOnboarding = await api.completeOnboarding(
                onboarding.export(),
                onboardingId,
            );

            onboarding.import(completedOnboarding);
            setDidError(false);
            setDidComplete(true);
        } catch (err) {
            console.error(err);

            setSubmitting(false);
            setDidError(true);
        }
    };

    const onFinishEditing = () => history.push('/my-account/passport');

    const renderIntro = () => {
        return !onboardingComplete ? (
            <p className="mb-4">
                You can edit your details by navigating to the sections below. Please ensure you
                click 'Save and Continue' to save your progress in any given section.
            </p>
        ) : (
            <>
                <p className="mb-4">
                    Below are a list of the sections you'll need to complete. You can save your
                    progress and return at a later date to continue.
                </p>
                <p className="mb-4">
                    These are the documents that you will need to upload as part of your
                    application:
                </p>
                <ul className="pl-8 mb-4">
                    <li className="list-disc">Proof of Identity</li>
                    <li className="list-disc">Proof of Indemnity</li>
                    {!onboardingLite && (
                        <>
                            <li className="list-disc">
                                Employment history and professional qualifications
                            </li>
                            <li className="list-disc">DBS Certification</li>
                        </>
                    )}
                </ul>
            </>
        );
    };

    if (didComplete) {
        return <Redirect to="/my-account/passport?onboardingComplete=true" />;
    }

    if (onboardingLite) {
        return (
            <OnboardingUserContext.Provider value={onSubmitStep}>
                <Wizard
                    completeText={onboardingComplete ? 'Finish Editing' : 'Complete Application'}
                    initialValues={onboarding.values}
                    stepNumber={step ? Number(step) : 0}
                    onSubmit={onboardingComplete ? onFinishEditing : onSubmit}
                    didError={didError}
                    setDidError={setDidError}
                    validationSchemas={onboarding.schemas}
                    invalidSections={invalidSections}
                    errorText="We were unable to process your application. Please try again."
                    intro={renderIntro()}
                >
                    <WizardStep
                        heading="Personal Details"
                        id="personalDetails"
                        onSubmit={onSubmitStep}
                    >
                        {(values) => (
                            <>
                                <Input
                                    name="name"
                                    label="Name"
                                    placeholder="e.g. Jane Smith"
                                    disabled
                                />
                                <Input
                                    name="email"
                                    type="email"
                                    label="Email Address"
                                    placeholder="e.g. jane.smith@example.com"
                                    disabled
                                />
                                <Input
                                    name="phoneNumber"
                                    label="Mobile Phone Number"
                                    placeholder="e.g. +44 7738 123 456"
                                    disabled
                                />
                                <Input
                                    name="dateOfBirth"
                                    label="Date of Birth"
                                    placeholder="e.g. 14/06/1990"
                                />
                            </>
                        )}
                    </WizardStep>

                    <WizardStep
                        heading="Doc Abode Preferences"
                        id="preferences"
                        onSubmit={onSubmitStep}
                    >
                        {(values) => (
                            <>
                                <p className="mb-2">
                                    Please identify all specialities that you have expertise,
                                    interest, experience or qualifications in:&nbsp;
                                    <span className="text-xs">(please select all that apply)</span>
                                </p>

                                <div className="flex flex-wrap mb-4">
                                    {specialityOptions.map((speciality) => {
                                        return (
                                            <div className="w-1/2" key={speciality}>
                                                <Checkbox name="specialities" value={speciality}>
                                                    {speciality}
                                                </Checkbox>
                                            </div>
                                        );
                                    })}
                                </div>

                                <Input name="specialitiesOther" label="If other, please specify" />

                                <p className="mb-2">
                                    Please identify the languages that you would be comfortable in
                                    consulting in during a case:&nbsp;
                                    <span className="text-xs">(please select all that apply)</span>
                                </p>

                                <div className="flex flex-wrap mb-4">
                                    {languageOptions.map((language) => {
                                        return (
                                            <div className="w-1/3" key={language}>
                                                <Checkbox name="languages" value={language}>
                                                    {language}
                                                </Checkbox>
                                            </div>
                                        );
                                    })}
                                </div>

                                <Input name="languagesOther" label="If other, please specify" />
                            </>
                        )}
                    </WizardStep>

                    <WizardStep
                        heading="Professional Details"
                        id="professionalDetails"
                        onSubmit={onSubmitStep}
                    >
                        {(values) => (
                            <Input
                                name="medicalIndemnityOrganisation"
                                label="Please state your Medical Indemnity Organisation"
                            >
                                E.g. MDU, MPS, MDDUS, CSP, or other (please specify)
                            </Input>
                        )}
                    </WizardStep>

                    <WizardStep
                        heading="Supporting Documentation"
                        id="supportingDocumentation"
                        onSubmit={onSubmitStep}
                    >
                        {(values) => (
                            <>
                                <FileInput
                                    label="Proof of Identity"
                                    category="PROOF_OF_RIGHT_TO_WORK"
                                >
                                    Please upload a copy of your passport, driving licence or other
                                    recognised photo ID.
                                </FileInput>

                                <FileInput label="Proof of Indemnity" category="PROOF_OF_INDEMNITY">
                                    Required where national scheme is not applicable.
                                </FileInput>
                            </>
                        )}
                    </WizardStep>

                    <WizardStep heading="Declaration" id="declaration">
                        {(values) => (
                            <>
                                <Checkbox name="confirmCorrect">
                                    I confirm that the information I have provided is correct and
                                    understand that misleading statements may be sufficient grounds
                                    for cancelling any agreements made.
                                </Checkbox>
                                <Checkbox name="confirmUnansweredFollowup">
                                    I understand that questions left unanswered or partially
                                    answered may be discussed following this application.
                                </Checkbox>
                                <Checkbox name="receiveMarketing">
                                    I am happy to receive marketing emails from Doc Abode in line
                                    with the{' '}
                                    <a
                                        className="text-pink-700 underline"
                                        href="https://docabode.com/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </Checkbox>
                            </>
                        )}
                    </WizardStep>
                </Wizard>
            </OnboardingUserContext.Provider>
        );
    }

    return (
        <OnboardingUserContext.Provider value={onSubmitStep}>
            <Wizard
                completeText={onboardingComplete ? 'Finish Editing' : 'Complete Application'}
                initialValues={onboarding.values}
                stepNumber={step ? Number(step) : 0}
                onSubmit={onboardingComplete ? onFinishEditing : onSubmit}
                didError={didError}
                setDidError={setDidError}
                validationSchemas={onboarding.schemas}
                invalidSections={invalidSections}
                errorText="We were unable to process your application. Please try again."
                intro={renderIntro()}
            >
                <WizardStep heading="Personal Details" id="personalDetails" onSubmit={onSubmitStep}>
                    {(values) => (
                        <>
                            <Input
                                name="name"
                                label="Name"
                                placeholder="e.g. Jane Smith"
                                disabled
                            />
                            <Input
                                name="email"
                                type="email"
                                label="Email Address"
                                placeholder="e.g. jane.smith@example.com"
                                disabled
                            />
                            <Input
                                name="phoneNumber"
                                label="Mobile Phone Number"
                                placeholder="e.g. +44 7738 123 456"
                                disabled
                            />

                            <Input
                                name="dateOfBirth"
                                label="Date of Birth"
                                placeholder="e.g. 14/06/1990"
                            />
                            <Input
                                name="niNumber"
                                label="National Insurance No."
                                placeholder="e.g. QQ123456C"
                            />
                            <Input
                                name="addressLine1"
                                label="Home Address 1"
                                placeholder="e.g. 42 Main Street"
                            />
                            <Input name="addressLine2" label="Home Address 2" />
                            <Input name="town" label="Home Town/City" placeholder="e.g. Leeds" />
                            <Input
                                name="county"
                                label="Home County"
                                placeholder="e.g. West Yorkshire"
                            />
                            <Input
                                name="postCode"
                                label="Home Postcode"
                                placeholder="e.g. LD12 ABC"
                            />

                            <TextArea
                                name="emergencyContact"
                                label="Emergency Contact"
                                placeholder=""
                            >
                                Please include your emergency contact's Name, Relationship to you,
                                Address, Daytime phone number and Evening phone number.
                            </TextArea>

                            <Radio
                                label="Device which will have the Doc Abode app installed?"
                                name="deviceType"
                                options={deviceOptions}
                            />
                        </>
                    )}
                </WizardStep>
                <WizardStep
                    heading="Financial Details"
                    id="financialDetails"
                    onSubmit={onSubmitStep}
                >
                    {(values) => (
                        <>
                            <p className="text-sm mb-6">
                                We collect this information so that the compensation for completed
                                work can be paid directly into your nominated bank account. These
                                details are held securely on our system and are not used for any
                                other purposes.
                            </p>

                            <Input
                                name="nameOnAccount"
                                label="Name on Account"
                                placeholder="e.g. Miss Jane Smith"
                            />
                            <Input
                                name="accountNumber"
                                label="Account Number"
                                placeholder="e.g. 12345678"
                            />
                            <Input
                                name="sortCode"
                                label="Sort Code"
                                placeholder="e.g. 112233 or 11-22-33"
                            />

                            <Radio
                                label="Are you engaging with Doc Abode or the healthcare provider as:"
                                name="tradingStatus"
                                options={tradingStatusOptions}
                            />

                            {values.tradingStatus === 'COMPANY' && (
                                <>
                                    <Input name="companyName" label="Company Name" />
                                    <Input
                                        name="companyRegistrationNumber"
                                        label="Company Registration Number"
                                        placeholder="e.g. 12345678"
                                    />

                                    <Radio
                                        label="Are you VAT registered?"
                                        name="vatRegistered"
                                        options={yesNoOptions}
                                    />

                                    {values.vatRegistered === 'yes' && (
                                        <Input
                                            name="vatRegistrationNumber"
                                            label="VAT Registration Number"
                                            placeholder="e.g. GB123456789"
                                        />
                                    )}
                                </>
                            )}

                            <Radio
                                label="Are you currently a member of the NHS pension scheme?"
                                name="inNHSPension"
                                options={yesNoOptions}
                            />

                            {values.inNHSPension === 'yes' && (
                                <>
                                    <Input
                                        name="pensionSDNumber"
                                        label="Pension SD number"
                                        placeholder="e.g. 12345678"
                                    />

                                    <p className="text-sm mb-6">
                                        Your pension SD number is eight numerical digits, often
                                        prefixed with "SD". You will find it on correspondence sent
                                        to you from NHS pensions, on some payslips or on your online
                                        Total Reward Statement. If you can't find it you can call
                                        the NHS Pensions Helpline on 0300 330 1346.
                                    </p>

                                    <Select
                                        name="pensionTierDeduction"
                                        label="Pension scheme tier % deduction"
                                    >
                                        <option value="">Please select...</option>
                                        {pensionTierDeductionOptions.map((option) => (
                                            <option key={option}>{option}</option>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </>
                    )}
                </WizardStep>
                <WizardStep
                    heading="Equality &amp; Diversity"
                    id="equalityAndDiversity"
                    onSubmit={onSubmitStep}
                >
                    {(values) => (
                        <>
                            <p className="text-sm mb-6">
                                This information is collected for equality and diversity monitoring
                                only under the terms of the terms of the Equality Act 2010 and in
                                line with NHS England and NHS Digital best practice and is not
                                compulsory.
                            </p>

                            <Select name="equalityGender" label="Gender">
                                <option value="">Please select...</option>
                                {equalityOptions.gender.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            {values.equalityGender === 'Let me type...' && (
                                <Input
                                    name="equalityGenderOther"
                                    type="text"
                                    label="You can specify your gender here"
                                />
                            )}

                            <Select name="equalityMaritalStatus" label="Marital Status">
                                <option value="">Please select...</option>
                                {equalityOptions.maritalStatus.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalityEthnicOrigin" label="Ethnic Origin">
                                <option value="">Please select...</option>
                                {equalityOptions.ethnicOrigin.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalityCountryOfBirth" label="Country of Birth">
                                <option value="">Please select...</option>
                                {equalityOptions.countryOfBirth.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalityNationality" label="Nationality">
                                <option value="">Please select...</option>
                                {equalityOptions.nationality.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalityDisability" label="Disability">
                                <option value="">Please select...</option>
                                {equalityOptions.disability.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalityReligiousBeliefs" label="Religious Beliefs">
                                <option value="">Please select...</option>
                                {equalityOptions.religiousBelief.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>

                            <Select name="equalitySexualOrientation" label="Sexual Orientation">
                                <option value="">Please select...</option>
                                {equalityOptions.sexualOrientation.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Select>
                        </>
                    )}
                </WizardStep>

                <WizardStep
                    heading="Doc Abode Preferences"
                    id="preferences"
                    onSubmit={onSubmitStep}
                >
                    {(values) => (
                        <>
                            <p className="mb-2">
                                Please identify all specialities that you have expertise, interest,
                                experience or qualifications in:&nbsp;
                                <span className="text-xs">(please select all that apply)</span>
                            </p>

                            <div className="flex flex-wrap mb-4">
                                {specialityOptions.map((speciality) => {
                                    return (
                                        <div className="w-1/2" key={speciality}>
                                            <Checkbox name="specialities" value={speciality}>
                                                {speciality}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>

                            <Input name="specialitiesOther" label="If other, please specify" />

                            <p className="mb-2">
                                Please identify the languages that you would be comfortable in
                                consulting in during a case:&nbsp;
                                <span className="text-xs">(please select all that apply)</span>
                            </p>

                            <div className="flex flex-wrap mb-4">
                                {languageOptions.map((language) => {
                                    return (
                                        <div className="w-1/3" key={language}>
                                            <Checkbox name="languages" value={language}>
                                                {language}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>

                            <Input name="languagesOther" label="If other, please specify" />
                        </>
                    )}
                </WizardStep>

                <WizardStep
                    heading="Professional Details"
                    id="professionalDetails"
                    onSubmit={onSubmitStep}
                >
                    {(values) => {
                        const didSelectGP =
                            values.hcpType && values.hcpType.length > 0 && values.hcpType === 'GP';
                        const professionalBodyRequired =
                            values.professionalBodyRegistrationRequired === 'yes' && !didSelectGP;
                        const selectedProfessionalBodyType =
                            values.professionalBodyType && values.professionalBodyType.length > 0
                                ? professionalBodies[values.professionalBodyType]
                                : null;

                        return (
                            <>
                                <p className="text-sm mb-6">
                                    Some of this information may have been collected during
                                    pre-screening, in which case it cannot be changed.
                                </p>

                                <Select name="hcpType" label="Professional Status" disabled>
                                    <option value="">Please select...</option>
                                    {hcpTypes.map((type) => (
                                        <option key={type.value}>{type.label}</option>
                                    ))}
                                </Select>

                                {values.hcpType && !didSelectGP && (
                                    <Radio
                                        label="Does your role require you to be registered with a professional body?"
                                        name="professionalBodyRegistrationRequired"
                                        options={yesNoOptions}
                                        disabled={!onboardingExistingUser}
                                    />
                                )}

                                {professionalBodyRequired && (
                                    <div>
                                        <Select
                                            name="professionalBodyType"
                                            label="Professional Body"
                                            disabled={!onboardingExistingUser}
                                        >
                                            <option value="">Please select...</option>
                                            {professionalBodyOptions.map((option) => {
                                                return (
                                                    <option key={option.value} value={option.value}>
                                                        {option.name}
                                                    </option>
                                                );
                                            })}
                                        </Select>

                                        {values.professionalBodyType === 'other' && (
                                            <Input
                                                name="professionalBodyTypeOther"
                                                placeholder="Enter the professional body's name here"
                                                disabled={!onboardingExistingUser}
                                            />
                                        )}

                                        <Input
                                            name="professionalBodyId"
                                            label="Professional Body ID"
                                            placeholder={
                                                selectedProfessionalBodyType
                                                    ? selectedProfessionalBodyType.placeholder
                                                    : null
                                            }
                                            disabled={!onboardingExistingUser}
                                        />
                                    </div>
                                )}

                                {onboarding.values.hcpType === 'GP' ? (
                                    <div>
                                        <Select
                                            name="primaryGPFunction"
                                            label="What is your primary GP function?"
                                            disabled={!onboardingExistingUser}
                                        >
                                            <option value="">Please select...</option>
                                            {primaryGPFunctions.map((option) => (
                                                <option key={option}>{option}</option>
                                            ))}
                                        </Select>

                                        <Input
                                            name="professionalBodyId"
                                            label="GMC Registration Number"
                                            placeholder={professionalBodies.GMC.placeholder}
                                            disabled={!onboardingExistingUser}
                                        />

                                        <Input
                                            name="gmcRegistrationDate"
                                            label="Date of full GMC registration"
                                            placeholder="e.g. 14/06/1995"
                                            disabled={!onboardingExistingUser}
                                        />

                                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                            Declarations
                                        </p>

                                        <Checkbox
                                            name="confirmComplaints"
                                            disabled={!onboardingExistingUser}
                                        >
                                            I am not aware of any complaints against me that have
                                            been referred to independent review by NHSE or the GMC.
                                        </Checkbox>

                                        <Checkbox
                                            name="confirmRestrictions"
                                            disabled={!onboardingExistingUser}
                                        >
                                            I have no restrictions on practice or changes in GMC
                                            registration status.
                                        </Checkbox>

                                        <Checkbox
                                            name="confirmOnPerformersList"
                                            disabled={!onboardingExistingUser}
                                        >
                                            I am currently included on the{' '}
                                            <a
                                                className="text-pink-700 underline"
                                                href="https://secure.pcse.england.nhs.uk/PerformersLists"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                NHS England Performers List
                                            </a>
                                            .
                                        </Checkbox>

                                        <Checkbox
                                            name="confirmNotPerformersListSuspended"
                                            disabled={!onboardingExistingUser}
                                        >
                                            I have not been suspended from the{' '}
                                            <a
                                                className="text-pink-700 underline"
                                                href="https://secure.pcse.england.nhs.uk/PerformersLists"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                NHS England Performers List
                                            </a>
                                            .
                                        </Checkbox>

                                        <Checkbox
                                            name="confirmObligations"
                                            disabled={!onboardingExistingUser}
                                        >
                                            I accept the professional obligations placed on me in{' '}
                                            <a
                                                className="text-pink-700 underline"
                                                href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/good-medical-practice/domain-2----safety-and-quality#paragraph-28"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Good Medical Practice paragraphs 28-30 (GMC 2013)
                                            </a>{' '}
                                            about my personal health.
                                        </Checkbox>
                                    </div>
                                ) : (
                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                        Declarations
                                    </p>
                                )}

                                <Checkbox
                                    name="confirmAcceptTerms"
                                    disabled={!onboardingExistingUser}
                                >
                                    I accept the Doc Abode{' '}
                                    <a
                                        className="text-pink-700 underline"
                                        href="https://docabode.com/terms-of-use"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms and Conditions
                                    </a>
                                    .
                                </Checkbox>
                                <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                    Practice Details (if applicable)
                                </p>
                                <p className="text-sm mb-6">
                                    This will be used to notify you of Doc Abode home visit and
                                    telephone call matches to patients registered under your
                                    practice.
                                </p>
                                <Input name="practiceName" label="Name" />
                                <Input name="practiceODSCode" label="ODS Code" />
                                <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                    Other Details
                                </p>
                                <Input name="nhsSmartcardNo" label="NHS Smartcard Number" />
                                <Input name="nhsEmail" label="NHS Email Address">
                                    If different to the email address you used to register
                                </Input>
                                <Input
                                    name="medicalIndemnityOrganisation"
                                    label="Please state your Medical Indemnity Organisation"
                                >
                                    E.g. MDU, MPS, MDDUS, CSP, or other (please specify)
                                </Input>
                                <FileInput
                                    label="Employment history and professional qualifications"
                                    category="EMPLOYMENT_HISTORY"
                                    noExpiry
                                >
                                    Please upload an up-to-date CV, ensuring that for each position
                                    you have held, you include Employer, Position held and Dates of
                                    Employment (from-to).
                                </FileInput>
                            </>
                        );
                    }}
                </WizardStep>

                <WizardStep heading="Transport" id="transport" onSubmit={onSubmitStep}>
                    {(values) => (
                        <>
                            <Radio
                                label="Do you possess a current driving licence?"
                                name="hasCurrentDrivingLicence"
                                options={yesNoOptions}
                            />

                            {values.hasCurrentDrivingLicence === 'yes' && (
                                <>
                                    <Input
                                        name="drivingLicenceNumber"
                                        label="Driving licence number"
                                    />

                                    <Radio
                                        label="Do you hold valid business insurance for your car?"
                                        name="hasCarBusinessInsurance"
                                        options={yesNoOptions}
                                    />

                                    <Radio
                                        label="Have you ever been convicted of an offence (including motoring but not parking offences) or are any charges or summons at present outstanding against you?"
                                        name="hasConviction"
                                        options={yesNoOptions}
                                    />

                                    {values.hasConviction === 'yes' && (
                                        <TextArea
                                            name="convictionDetails"
                                            label="Please give details"
                                        >
                                            <strong>Note:</strong> By virtue of the Rehabilitation
                                            of Offender's Act 1974, Section 4(2) of the
                                            Rehabilitation of Offender's Act 1974 does not apply to
                                            this question. You are therefore not entitled to
                                            withhold information about a previous conviction on the
                                            grounds that it is for other purposes spent under the
                                            Act.
                                        </TextArea>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </WizardStep>
                <WizardStep heading="First Reference" id="firstReference" onSubmit={onSubmitStep}>
                    {(values) => (
                        <>
                            <p className="text-sm mb-6">
                                Please note that at least one reference should be your current or
                                most recent employer and must be in clinical practice.
                            </p>
                            <Input
                                name="reference1FullName"
                                label="Full Name"
                                placeholder="e.g. Jane Smith"
                            />
                            <Input
                                name="reference1Address1"
                                label="Address 1"
                                placeholder="e.g. 42 Main Street"
                            />
                            <Input name="reference1Address2" label="Address 2" />
                            <Input
                                name="reference1Town"
                                label="Town/City"
                                placeholder="e.g. Leeds"
                            />
                            <Input
                                name="reference1County"
                                label="County"
                                placeholder="e.g. West Yorkshire"
                            />
                            <Input
                                name="reference1PostCode"
                                label="Postcode"
                                placeholder="e.g. LD12 ABC"
                            />

                            <Input
                                name="reference1Telephone"
                                label="Telephone"
                                placeholder="e.g. 01234 456789"
                            />
                            <Input
                                name="reference1Email"
                                type="email"
                                label="Email"
                                placeholder="e.g. jane.smith@exmaple.com"
                            />
                            <Input
                                name="reference1EmailConfirm"
                                type="email"
                                label="Confirm Email"
                                placeholder="e.g. jane.smith@exmaple.com"
                            />
                            <FileInput
                                label="Optional: upload reference"
                                category="REFERENCE1"
                                noExpiry
                            >
                                If you already have a written reference from this person, you can
                                upload it here.
                            </FileInput>
                        </>
                    )}
                </WizardStep>
                <WizardStep heading="Second Reference" id="secondReference" onSubmit={onSubmitStep}>
                    {(values) => (
                        <>
                            <p className="text-sm mb-6">
                                Please note that at least one reference should be your current or
                                most recent employer and must be in clinical practice.
                            </p>
                            <Input
                                name="reference2FullName"
                                label="Full Name"
                                placeholder="e.g. Jane Smith"
                            />
                            <Input
                                name="reference2Address1"
                                label="Address 1"
                                placeholder="e.g. 42 Main Street"
                            />
                            <Input name="reference2Address2" label="Address 2" />
                            <Input
                                name="reference2Town"
                                label="Town/City"
                                placeholder="e.g. Leeds"
                            />
                            <Input
                                name="reference2County"
                                label="County"
                                placeholder="e.g. West Yorkshire"
                            />
                            <Input
                                name="reference2PostCode"
                                label="Postcode"
                                placeholder="e.g. LD12 ABC"
                            />

                            <Input
                                name="reference2Telephone"
                                label="Telephone"
                                placeholder="e.g. 01234 456789"
                            />
                            <Input
                                name="reference2Email"
                                type="email"
                                label="Email"
                                placeholder="e.g. jane.smith@exmaple.com"
                            />
                            <Input
                                name="reference2EmailConfirm"
                                type="email"
                                label="Confirm Email"
                                placeholder="e.g. jane.smith@exmaple.com"
                            />
                            <FileInput
                                label="Optional: upload reference"
                                category="REFERENCE2"
                                noExpiry
                            >
                                If you already have a written reference from this person, you can
                                upload it here.
                            </FileInput>
                        </>
                    )}
                </WizardStep>
                <WizardStep
                    heading="Supporting Documentation"
                    id="supportingDocumentation"
                    onSubmit={onSubmitStep}
                >
                    {(values) => (
                        <>
                            <FileInput
                                label="Proof of Right to Work"
                                category="PROOF_OF_RIGHT_TO_WORK"
                            >
                                Please upload a copy of your passport, driving licence or other
                                recognised photo ID.
                            </FileInput>

                            <FileInput label="DBS Certification" category="DBS_CERT" />

                            <FileInput label="Proof of Indemnity" category="PROOF_OF_INDEMNITY">
                                Required where national scheme is not applicable.
                            </FileInput>

                            <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                Vaccination &amp; Test Records
                            </p>
                            <p className="text-sm mb-6">
                                All staff should be up to date with their routine vaccinations e.g.
                                tetanus, diptheria, polio and MMR. Satisfactory evidence of
                                protection would include documentation of having received two doses
                                of MMR or having had positive antibody tests for measles and
                                rubella. Evidence of antibody titres for hepatitis B is also
                                required. Tuberculosis BCG vaccination is also recommended for
                                healthcare workers.
                            </p>

                            <p className="mb-2">
                                Please select which routine vaccinations you have received:
                            </p>
                            <div className="flex flex-wrap mb-4 justify-between">
                                {vaccinations.map((vaccination) => {
                                    return (
                                        <Checkbox
                                            name="vaccinations"
                                            value={vaccination}
                                            key={vaccination}
                                        >
                                            {vaccination}
                                        </Checkbox>
                                    );
                                })}
                            </div>

                            <FileInput
                                label="Please upload evidence of your vaccinations if available"
                                category="VACCINATIONS"
                            />

                            <Select name="covidStatus" label="Latest Covid-19 status">
                                <option value="">Please select...</option>
                                {covidStatuses.map((option) => (
                                    <option key={option}>{option}</option>
                                ))}
                            </Select>
                        </>
                    )}
                </WizardStep>
                <WizardStep
                    heading="Training and Development"
                    id="trainingAndDevelopment"
                    onSubmit={onSubmitStep}
                >
                    {(values) => (
                        <>
                            <p className="text-sm mb-6">
                                Please upload certification for each of the following items if
                                available. The healthcare provider's HR team may be in touch if
                                there are any omissions which may affect your registration.
                            </p>

                            <p className="mb-2">
                                Please select which training you have undertaken and have the
                                appropriate certification for:
                            </p>
                            <div className="flex flex-wrap mb-4">
                                {trainingOptions.sort(sortAlpha).map((trainingOption) => {
                                    return (
                                        <div className="w-1/2" key={trainingOption.name}>
                                            <Checkbox name="training" value={trainingOption.name}>
                                                {trainingOption.name}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>

                            {trainingOptions.map((trainingOption) => {
                                return values.training.includes(trainingOption.name) ? (
                                    <FileInput
                                        key={trainingOption.category}
                                        label={trainingOption.name}
                                        category={trainingOption.category}
                                    >
                                        Please upload evidence of your highest level of{' '}
                                        {trainingOption.name} certification.
                                    </FileInput>
                                ) : null;
                            })}

                            <Radio
                                label="Are you registered on www.e-lfh.co.uk?"
                                name="elfhRegistered"
                                options={yesNoOptions}
                            />

                            <p className="mb-2">
                                Please confirm which systems you are proficient with:
                            </p>
                            <div className="flex flex-wrap mb-4">
                                {systemProficiencyOptions.map((option) => {
                                    return (
                                        <div className="w-1/2" key={option}>
                                            <Checkbox name="systemProficiency" value={option}>
                                                {option}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>

                            <Input name="systemProficiencyOther" label="If other, please specify" />
                        </>
                    )}
                </WizardStep>
                <WizardStep heading="Declaration" id="declaration">
                    {(values) => (
                        <>
                            <Checkbox name="confirmCorrect">
                                I confirm that the information I have provided is correct and
                                understand that misleading statements may be sufficient grounds for
                                cancelling any agreements made.
                            </Checkbox>
                            <Checkbox name="confirmUnansweredFollowup">
                                I understand that questions left unanswered or partially answered
                                may be discussed following this application.
                            </Checkbox>
                            <Checkbox name="receiveMarketing">
                                I am happy to receive marketing emails from Doc Abode in line with
                                the{' '}
                                <a
                                    className="text-pink-700 underline"
                                    href="https://docabode.com/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </Checkbox>
                            <p className="my-3 text-sm">
                                I hereby confirm that I am currently up to date in regard to
                                mandatory training in the following areas:
                            </p>
                            <Checkbox name="confirmBasicAdvancedLifeSupport">
                                Basic/Advanced Life Support
                            </Checkbox>
                            <Checkbox name="confirmSafeguardingAdults">
                                Safeguarding Adults
                            </Checkbox>
                            <Checkbox name="confirmSafeguardingChildren">
                                Safeguarding Children
                            </Checkbox>
                        </>
                    )}
                </WizardStep>
            </Wizard>
        </OnboardingUserContext.Provider>
    );
};

export default OnboardingWizard;
