import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

import App from './App';
import packageInfo from '../package.json';
// import * as serviceWorker from './serviceWorker';

const { REACT_APP_ENVIRONMENT: ENV, NODE_ENV } = process.env;

if (NODE_ENV === 'production') {
    Sentry.init({
        environment: ENV,
        release: packageInfo.version,
        dsn: 'https://b9ed58c47bab4037b9968212f314f72e@o421207.ingest.sentry.io/5395325',
        integrations: [new CaptureConsole()],
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
