import React from 'react';
import { Formik, Form } from 'formik';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';

const ResetPassword = ({ didError, onResetPassword, onResendCode }) => {
    const initialValues = {
        code: '',
        newPassword: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onResetPassword}>
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Reset Password
                        </p>

                        <p className="text-sm mb-6">
                            We've sent a verification code to your registered email address or phone
                            number, please enter it below along with your new password of choice.
                        </p>

                        <Input
                            name="code"
                            type="text"
                            inputmode="numeric"
                            label="Verification Code"
                            placeholder="e.g. 123456"
                            disabled={isSubmitting}
                        />
                        <Input
                            name="newPassword"
                            type="password"
                            label="New Password"
                            autocomplete="new-password"
                            disabled={isSubmitting}
                        />

                        <p className="text-sm mb-6">
                            <button
                                type="button"
                                className="text-pink-700 underline"
                                onClick={onResendCode}
                            >
                                Resend Verification Code
                            </button>
                        </p>

                        {didError && (
                            <p className="text-sm text-center text-red-700 mb-4">
                                Something went wrong. Please try again.
                            </p>
                        )}

                        <SubmitButton isSubmitting={isSubmitting} text="Reset Password" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResetPassword;
