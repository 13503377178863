import React from 'react';
import { useField } from 'formik';

const Select = ({ children, disabled = false, label, name, ...props }) => {
    const [field, meta] = useField({
        ...props,
        name,
    });

    const showError = meta.touched && meta.error;

    return (
        <div className="mb-4">
            <label className="block">
                <span className={`${showError ? 'text-red-700' : 'text-gray-700'}`}>{label}</span>
                <select
                    className={`form-select mt-1 block w-full ${
                        showError ? 'border-red-700 text-red-700' : ''
                    } ${disabled ? 'opacity-75' : ''}`}
                    {...field}
                    disabled={disabled}
                >
                    {children}
                </select>
            </label>
            {showError && (
                <div className="text-sm text-red-700 mt-2">{meta.error.split(' | ')[1]}</div>
            )}
        </div>
    );
};

export default Select;
