const getInviteStyles = (flag) => {
    let bgClassName, textClassName500, textClassName700, borderClassName500, borderClassName700;

    switch (flag) {
        case 'APPROVED':
        case 'Complete':
            bgClassName = 'bg-green-100';
            textClassName500 = 'text-green-500';
            textClassName700 = 'text-green-700';
            borderClassName500 = 'border-green-500';
            borderClassName700 = 'border-green-700';
            break;
        case 'IN_PROGRESS':
        case 'Optional':
            bgClassName = 'bg-blue-100';
            textClassName500 = 'text-blue-500';
            textClassName700 = 'text-blue-700';
            borderClassName500 = 'border-blue-500';
            borderClassName700 = 'border-blue-700';
            break;
        case 'MISSING_INFORMATION':
            bgClassName = 'bg-orange-100';
            textClassName500 = 'text-orange-500';
            textClassName700 = 'text-orange-700';
            borderClassName500 = 'border-orange-500';
            borderClassName700 = 'border-orange-700';
            break;
        case 'REJECTED':
        case 'Incomplete':
            bgClassName = 'bg-red-100';
            textClassName500 = 'text-red-500';
            textClassName700 = 'text-red-700';
            borderClassName500 = 'border-red-500';
            borderClassName700 = 'border-red-700';
            break;
        default:
            bgClassName = 'bg-gray-100';
            textClassName500 = 'text-gray-500';
            textClassName700 = 'text-gray-700';
            borderClassName500 = 'border-gray-500';
            borderClassName700 = 'border-gray-700';
            break;
    }

    return {
        bgClassName,
        textClassName500,
        textClassName700,
        borderClassName500,
        borderClassName700,
    };
};

export default getInviteStyles;
