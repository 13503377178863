import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import api from '../../utils/api';
import { systemProficiencyOptions, yesNoOptions } from '../../utils/lookups';
import { logJSON } from '../../utils/shared';

import Checkbox from '../shared/forms/Checkbox';
import Input from '../shared/forms/Input';
import Radio from '../shared/forms/Radio';
import Recaptcha from '../shared/forms/Recaptcha';
import SubmitButton from '../shared/forms/SubmitButton';

const initialValues = {
    organisationName: '',
    organisationAddressLine1: '',
    organisationAddressLine2: '',
    organisationTown: '',
    organisationCounty: '',
    organisationPostCode: '',
    organisationODSCode: '',
    leadContactFirstName: '',
    leadContactLastName: '',
    leadContactPhoneNumber: '',
    leadContactEmail: '',
    leadContactEmailConfirm: '',
    leadContactPatientFacing: null,
    systemProficiency: [],
    systemProficiencyOther: '',
    confirmCorrect: false,
    confirmPotentialFollowUp: false,
    receiveMarketing: false,
    confirmAcceptTerms: false,
    recaptcha: null,
};

const validationSchema = Yup.object({
    organisationName: Yup.string().required('Organisation name | This field is required.'),

    organisationAddressLine1: Yup.string().required(
        'Organisation address line 1 | This field is required.',
    ),

    organisationAddressLine2: Yup.string(),

    organisationTown: Yup.string().required('Organisation town | This field is required.'),

    organisationCounty: Yup.string(),

    organisationPostCode: Yup.string().required('Organisation post code | This field is required.'),

    leadContactFirstName: Yup.string().required(
        'Primary contact first name | This field is required.',
    ),

    leadContactLastName: Yup.string().required(
        'Primary contact last name | This field is required.',
    ),

    leadContactPhoneNumber: Yup.string().required(
        'Primary contact phone number | This field is required.',
    ),

    leadContactEmail: Yup.string()
        .email('Primary contact email address | Invalid email address provided.')
        .required('Primary contact email address | This field is required.'),

    leadContactEmailConfirm: Yup.string()
        .email('Confirm primary contact email address | Invalid email address provided.')
        .required('Confirm primary contact email address | This field is required.')
        .test(
            'emails-match',
            "Confirm primary contact email address | Email address doesn't match.",
            function (value) {
                return this.parent.leadContactEmail === value;
            },
        ),

    leadContactPatientFacing: Yup.string()
        .nullable()
        .test(
            'pbr-required',
            'Is the primary contact patient facing? | This field is required.',
            function (value) {
                return (
                    this.parent.professionalStatus === 'GP' ||
                    (this.parent.professionalStatus !== 'GP' && value && value.length > 0)
                );
            },
        ),

    systemProficiency: Yup.array().required(
        'Please confirm which patient management system(s) your organisation uses | Please select at least one option.',
    ),

    systemProficiencyOther: Yup.string().when('systemProficiency', (systemProficiency, schema) => {
        return systemProficiency.find((option) => option === 'Other')
            ? schema.required(
                  'Please confirm which patient management system(s) your organisation uses (other) | This field is required.',
              )
            : schema;
    }),

    confirmCorrect: Yup.boolean().oneOf(
        [true],
        'I confirm that the information I have provided is correct and understand that misleading statements may be sufficient grounds for cancelling any agreements made | This field is required.',
    ),

    confirmPotentialFollowUp: Yup.boolean().oneOf(
        [true],
        'I understand that questions left unanswered or partially answered may be discussed following this application | This field is required.',
    ),

    receiveMarketing: Yup.boolean(),

    confirmAcceptTerms: Yup.boolean().oneOf(
        [true],
        'I accept the Doc Abode Terms and Conditions | This field is required.',
    ),

    recaptcha: Yup.string().nullable().required('Please complete the Recaptcha.'),
});

const OrganisationApplication = () => {
    const [didComplete, setDidComplete] = useState(false);
    const [didError, setDidError] = useState(false);

    const onSubmit = async (values, { setSubmitting }) => {
        const formattedPayload = {
            ...values,
            leadContactFirstName: undefined,
            leadContactLastName: undefined,
            leadContactName: `${values.leadContactFirstName} ${values.leadContactLastName}`,
            leadContactPatientFacing: values.leadContactPatientFacing === 'yes',
        };

        logJSON(formattedPayload);

        try {
            await api.organisationPrescreen(formattedPayload);

            setSubmitting(false);
            setDidError(false);
            setDidComplete(true);
        } catch (error) {
            setSubmitting(false);
            setDidError(true);
        }
    };

    return (
        <>
            <p className="text-2xl font-light text-pink-700 mb-4">Organisation Registration</p>

            {didComplete && (
                <div>
                    <p className="mx-12">
                        We have received your application and will be in touch in due course.
                    </p>
                </div>
            )}

            {!didComplete && (
                <>
                    <p className="text-sm mb-8">
                        This registration form is for organisations to submit the necessary
                        information for verification in order to use the Doc Abode platform to
                        assign cases to healthcare professionals.
                    </p>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, isSubmitting }) => {
                            const didSelectSystemsProficiencyOther =
                                Array.isArray(values.systemProficiency) &&
                                values.systemProficiency.find((option) => option === 'Other');

                            return (
                                <Form className="text-left">
                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 mb-4">
                                        Organisation Details
                                    </p>

                                    <Input
                                        name="organisationName"
                                        label="Organisation Name"
                                        placeholder="e.g. Example Practice"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="organisationODSCode"
                                        label="Organisation ODS Code"
                                        placeholder="e.g. Y012345"
                                        disabled={isSubmitting}
                                    >
                                        If you are unsure of your organisation's ODS code, you can
                                        visit{' '}
                                        <a
                                            className="text-pink-700 underline"
                                            href="https://odsportal.digital.nhs.uk/Organisation/Search"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://odsportal.digital.nhs.uk/Organisation/Search
                                        </a>{' '}
                                        to retrieve it.
                                    </Input>

                                    <Input
                                        name="organisationAddressLine1"
                                        label="Organisation Address 1"
                                        placeholder="e.g. 42 Main Street"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="organisationAddressLine2"
                                        label="Organisation Address 2"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="organisationTown"
                                        label="Organisation Town/City"
                                        placeholder="e.g. Leeds"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="organisationCounty"
                                        label="Organisation County"
                                        placeholder="e.g. West Yorkshire"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="organisationPostCode"
                                        label="Organisation Postcode"
                                        placeholder="e.g. LD12 ABC"
                                        disabled={isSubmitting}
                                    />

                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                        Primary Contact Details
                                    </p>

                                    <Input
                                        name="leadContactFirstName"
                                        label="First Name"
                                        placeholder="e.g. Jane"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="leadContactLastName"
                                        label="Last Name"
                                        placeholder="e.g. Smith"
                                        disabled={isSubmitting}
                                    />

                                    <Input
                                        name="leadContactPhoneNumber"
                                        label="Non-Patient Facing Phone Number"
                                        placeholder="e.g. +44 7738 123 456"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="leadContactEmail"
                                        type="email"
                                        label="Non-Patient Facing Email Address"
                                        placeholder="e.g. jane.smith@example.com"
                                        disabled={isSubmitting}
                                    />
                                    <Input
                                        name="leadContactEmailConfirm"
                                        type="email"
                                        label="Confirm Email Address"
                                        placeholder="e.g. jane.smith@example.com"
                                        disabled={isSubmitting}
                                    />

                                    <Radio
                                        label="Is the primary contact patient facing?"
                                        name="leadContactPatientFacing"
                                        options={yesNoOptions}
                                        disabled={isSubmitting}
                                    />

                                    <p className="mb-2">
                                        Please confirm which patient management system(s) your
                                        organisation uses:
                                    </p>
                                    <div className="flex flex-wrap mb-4">
                                        {systemProficiencyOptions.map((option) => {
                                            return (
                                                <div className="w-1/2" key={option}>
                                                    <Checkbox
                                                        name="systemProficiency"
                                                        value={option}
                                                    >
                                                        {option}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {didSelectSystemsProficiencyOther && (
                                        <Input
                                            name="systemProficiencyOther"
                                            label="If other, please specify"
                                        />
                                    )}

                                    <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                                        Declarations
                                    </p>

                                    <Checkbox name="confirmCorrect" disabled={isSubmitting}>
                                        I confirm that the information I have provided is correct
                                        and understand that misleading statements may be sufficient
                                        grounds for cancelling any agreements made.
                                    </Checkbox>

                                    <Checkbox
                                        name="confirmPotentialFollowUp"
                                        disabled={isSubmitting}
                                    >
                                        I understand that questions left unanswered or partially
                                        answered may be discussed following this application.
                                    </Checkbox>

                                    <Checkbox name="receiveMarketing" disabled={isSubmitting}>
                                        I am happy to receive marketing emails from Doc Abode in
                                        line with the{' '}
                                        <a
                                            className="text-pink-700 underline"
                                            href="https://docabode.com/privacy-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                        .
                                    </Checkbox>

                                    <Checkbox name="confirmAcceptTerms" disabled={isSubmitting}>
                                        I accept the Doc Abode{' '}
                                        <a
                                            className="text-pink-700 underline"
                                            href="https://docabode.com/terms-of-use"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms and Conditions
                                        </a>
                                        .
                                    </Checkbox>

                                    <Recaptcha />

                                    <div className="pt-2">
                                        {didError && (
                                            <p className="text-sm text-center text-red-700 mb-4">
                                                We were unable to process your application. Please
                                                try again.
                                            </p>
                                        )}

                                        <SubmitButton
                                            isSubmitting={isSubmitting}
                                            text="Submit My Details"
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};

export default OrganisationApplication;
