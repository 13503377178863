import axios from 'axios';

import { getSession } from './auth';
import config from './config';

const { REACT_APP_LOCAL_API_URL } = process.env;

const getAuthHeaders = async (options = {}) => {
    const session = await getSession();
    const Authorization = `${options.asBearerToken ? 'Bearer ' : ''}${session.tokens.id}`;

    const context = session
        ? {
              headers: {
                  Authorization,
              },
          }
        : {};

    return context;
};

const hrRotaBaseUrl = REACT_APP_LOCAL_API_URL || `${config.apiEndpoint}/hr-rota`;

class API {
    constructor() {
        this.hcpPrescreen = this.hcpPrescreen.bind(this);
        this.saveOnboarding = this.saveOnboarding.bind(this);

        this.client = axios.create({
            baseURL: config.apiEndpoint,
        });
    }

    async forgottenUsername(values) {
        const response = await this.client.post('users/forgotten-username', values);
        return response;
    }

    async hcpPrescreen(values) {
        const response = await this.client.post('onboarding/users', values);
        return response;
    }

    async organisationPrescreen(values) {
        const response = await this.client.post('onboarding/organisations', values);
        return response.data;
    }

    async getAvailableOrganisations() {
        const context = await getAuthHeaders();
        const response = await this.client.get('onboarding/organisations/approved', context);
        return response.data;
    }

    async getInvites() {
        const context = await getAuthHeaders();
        const response = await this.client.get('invites/users', context);
        return response.data;
    }

    async submitInvite(params) {
        const context = await getAuthHeaders();
        const response = await this.client.post('invites/users', params, context);
        return response.data;
    }

    async getUser() {
        const context = await getAuthHeaders();
        const session = await getSession();
        const response = await this.client.get(`users/${session.username}`, context);
        return response;
    }

    async getOnboarding() {
        const context = await getAuthHeaders();
        const response = await this.client.get('onboarding/users', context);
        return response.data[0];
    }

    async saveOnboarding(values, onboardingId, showSuccessPop, showErrorPop) {
        try {
            const context = await getAuthHeaders();
            await this.client.patch(`onboarding/users/${onboardingId}`, values, context);
            showSuccessPop();
        } catch (err) {
            console.error(err);
            showErrorPop(err.message);
        }
    }

    async completeOnboarding(values, onboardingId) {
        const context = await getAuthHeaders();
        const response = await this.client.post(
            `onboarding/users/${onboardingId}/complete`,
            values,
            context,
        );
        return response.data;
    }

    async getInvoicingLink() {
        const context = await getAuthHeaders();
        const response = await this.client.post('invoicing/users/account-link', null, context);
        return response.data;
    }

    async createConnectedAccount() {
        const context = await getAuthHeaders();
        const response = await this.client.post('invoicing/users', null, context);
        return response.data;
    }

    async getFileUploadParams(category) {
        const context = await getAuthHeaders();
        const response = await this.client.post(
            `onboarding/users/files/upload/${category}`,
            {},
            context,
        );
        return response.data;
    }

    async uploadFile(file, category, uploadParams, onUploadProgress) {
        const session = await getSession();
        const key = `${session.username}/${category}/${uploadParams.uploadId}/${file.name}`;
        const formData = new FormData();

        Object.keys(uploadParams.fields).forEach((key) => {
            formData.append(key, uploadParams.fields[key]);
        });

        formData.append('key', key);
        formData.append('file', file);

        const context = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        if (onUploadProgress) {
            context.onUploadProgress = (event) => onUploadProgress(event.loaded / event.total);
        }

        await this.client.post(uploadParams.url, formData, context);
        return key;
    }

    async confirmFileUpload(onboardingId, uploadId, params) {
        const context = await getAuthHeaders();
        const response = await this.client.post(
            `onboarding/users/${onboardingId}/uploads/${uploadId}/confirm`,
            params,
            context,
        );
        return response.data;
    }

    async getOrganisationOnboarding(onboardingId) {
        const context = await getAuthHeaders();
        const response = await this.client.get(`onboarding/organisations/${onboardingId}`, context);
        return response.data;
    }

    async downloadOrganisationFile(key) {
        const context = await getAuthHeaders();
        const response = await this.client.get(`onboarding/organisations/files/${key}`, context);
        return response.data.url;
    }

    async decodeToken(org, token) {
        const response = await this.client.post(`org/${org}/comms/decode-token`, { token });
        return response.data;
    }

    async getCredentials(org, token) {
        const context = await getAuthHeaders();
        const response = await this.client.post(`org/${org}/comms/get-credentials`, {}, context);
        return response.data;
    }

    async redeemInvite(token) {
        const context = await getAuthHeaders({ asBearerToken: true });
        const response = await this.client.post(
            `${hrRotaBaseUrl}/onboarding/redeem-invite`,
            { token },
            context,
        );
        return response.data;
    }
}

const api = new API();

export default api;
