import React from 'react';

const Progress = ({ percent, small = false, colour = 'green' }) => {
    let bgClassName = '';
    switch (colour) {
        case 'green':
            bgClassName = 'bg-green-600';
            break;
        case 'red':
            bgClassName = 'bg-red-600';
            break;
        default:
            bgClassName = 'bg-green-600';
            break;
    }
    return (
        <div className={`w-full rounded bg-gray-200 ${small ? 'h-2' : 'h-6'}`}>
            <div
                className={`rounded ${bgClassName} transition-all duration-300 ${
                    small ? 'h-2' : 'h-6'
                }`}
                style={{ width: `${percent}%` }}
            ></div>
        </div>
    );
};

export default Progress;
