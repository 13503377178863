import React from 'react';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

const CLIENT_SITE_KEY = process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY;

const Recaptcha = ({ disabled = false, name = 'recaptcha', ...props }) => {
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helpers] = useField({
        ...props,
        name,
    });

    const { setError, setValue } = helpers;
    const showError = meta.touched && meta.error;

    const onRecaptchaChange = (token) => {
        setValue(token, true);
        setError(null);
    };

    const onRecaptchaError = () => {
        setError('Unable to process Recaptcha, please try again');
    };

    return (
        <div className="mb-4">
            <div className="flex justify-center">
                <ReCAPTCHA
                    sitekey={CLIENT_SITE_KEY}
                    onChange={onRecaptchaChange}
                    onErrored={onRecaptchaError}
                />
            </div>
            {showError && <div className="text-sm text-red-700 text-center mt-2">{meta.error}</div>}
        </div>
    );
};

export default Recaptcha;
