import React from 'react';
import { useField } from 'formik';

const Checkbox = ({ children, disabled = false, name, ...props }) => {
    const [field, meta] = useField({
        ...props,
        name,
        type: 'checkbox',
    });

    const showError = meta.touched && meta.error;

    return (
        <div className="mb-4">
            <label className="flex items-center">
                <input
                    type="checkbox"
                    className={`form-checkbox ${showError ? 'text-red-700 border-red-700' : ''} ${
                        disabled ? 'opacity-75' : ''
                    }`}
                    {...field}
                    {...props}
                    disabled={disabled}
                />
                <span className={`ml-3 text-sm ${showError ? 'text-red-700' : 'text-gray-700'}`}>
                    {children}
                </span>
            </label>
        </div>
    );
};

export default Checkbox;
