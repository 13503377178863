import React from 'react';
import { Field, useField } from 'formik';

const Radio = ({ disabled = false, label, name, options = [], small = false, ...props }) => {
    // eslint-disable-next-line no-unused-vars
    const [field, meta] = useField({
        ...props,
        name,
        type: 'radio',
    });
    const showError = meta.touched && meta.error;

    return (
        <div className={`mb-4 ${small ? 'text-sm' : ''}`}>
            <span className={`${showError ? 'text-red-700' : 'text-gray-700'}`}>{label}</span>
            <div className="mt-2">
                <Field name={name}>
                    {({ field }) => {
                        return options.map((option) => {
                            return (
                                <label key={option.value} className="inline-flex items-center mr-6">
                                    <input
                                        type="radio"
                                        className={`form-radio ${disabled ? 'opacity-75' : ''}`}
                                        {...field}
                                        value={option.value}
                                        checked={field.value === option.value}
                                        disabled={disabled}
                                    />
                                    <span className="ml-2">{option.label}</span>
                                </label>
                            );
                        });
                    }}
                </Field>
            </div>
            {showError && (
                <div className="text-sm text-red-700 mt-2">{meta.error.split(' | ')[1]}</div>
            )}
        </div>
    );
};

export default Radio;
