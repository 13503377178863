import React, { useState, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import { ExclamationCircle, DocumentTextOutline, X } from 'heroicons-react';
import * as Yup from 'yup';

import SubmitButton from './SubmitButton';
import Progress from '../Progress';
import api from '../../../utils/api';
import { OnboardingUserContext } from '../../../utils/context';
import { parseDateString } from '../../../utils/shared';

const FIVE_MEGABYTES = 5242880;
const allowedMIMETypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
];

const schema = Yup.object({
    expiryDate: Yup.date()
        .transform(parseDateString)
        .typeError('Date must be in the format DD/MM/YYYY.')
        .min(new Date(), 'Date cannot be in the past.'),
});

const FileInput = ({ children, label, category, noExpiry, ...props }) => {
    const [field, meta, helpers] = useField({ name: 'files' });
    const onSubmitStep = useContext(OnboardingUserContext);

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [selectedFileError, setSelectedFileError] = useState('');
    const [expiryDateError, setExpiryDateError] = useState('');
    const [addAnotherFile, setAddAnotherFile] = useState(false);

    const files = field.value;
    const isUploading = uploadProgress > 0 && uploadProgress < 100;

    const reset = () => {
        setUploadProgress(0);
        setUploadComplete(false);
        setUploadError('');
        setSelectedFile(null);
        setSelectedFileError('');
    };

    const onReplaceFile = (uploadId) => {
        helpers.setValue(files.filter((file) => file.uploadId !== uploadId));
    };

    const onDrop = useCallback(async ([file]) => {
        const { type, size } = file;

        reset();
        setSelectedFile(file);

        if (size > FIVE_MEGABYTES) {
            setSelectedFileError('File is too large.');
        }

        if (!allowedMIMETypes.includes(type)) {
            setSelectedFileError('File type is not allowed.');
        }
    }, []);

    const onSubmit = async () => {
        try {
            setExpiryDateError('');

            try {
                schema.validateSync({ expiryDate });
            } catch (err) {
                setExpiryDateError(err.errors?.join(', ') || 'Invalid date.');
                return;
            }

            const uploadParams = await api.getFileUploadParams(category);

            const key = await api.uploadFile(
                selectedFile,
                category,
                uploadParams,
                setUploadProgress,
            );

            const updatedFiles = [
                ...files,
                {
                    category,
                    key,
                    fileName: selectedFile.name,
                    uploadId: uploadParams.uploadId,
                    uploadedAt: new Date().toISOString(),
                    expiryDate,
                },
            ];

            helpers.setValue(updatedFiles);
            setUploadComplete(true);
            setAddAnotherFile(false);
            onSubmitStep({ files: updatedFiles });
            reset();
        } catch (err) {
            setUploadError(err.message);
            console.error(err);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const existingFiles = files.filter((file) => file.category === category);
    const validationError =
        existingFiles.length === 0 &&
        meta.touched &&
        meta.error &&
        meta.error.split(' | ')[1]?.split(',').includes(category);

    return (
        <div className="mb-4 bg-gray-200 rounded py-2 pr-3 pl-2 border border-gray-300 flex">
            <div className="text-pink-700 pt-1">
                <DocumentTextOutline />
            </div>
            <div className="p-1 flex-grow">
                <h3 className="mb-2 text-pink-700">{label}</h3>
                {children && <div className="mb-2 text-sm">{children}</div>}
                {existingFiles.length > 0 && (
                    <ul>
                        {existingFiles.map(({ fileName, expiryDate, uploadId }) => (
                            <li
                                className="rounded mb-2 py-2 pl-3 pr-2 w-full text-sm flex border bg-blue-100 border-blue-700 text-blue-700 items-center"
                                key={uploadId}
                            >
                                <span className="flex flex-col">
                                    {fileName}
                                    {!noExpiry && (
                                        <span>Expiry: {expiryDate || 'not provided'}</span>
                                    )}
                                </span>
                                <button
                                    className="block rounded px-2 bg-gray-300 text-gray-700 ml-auto h-10"
                                    onClick={() => onReplaceFile(uploadId)}
                                    type="button"
                                >
                                    <X />
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                {existingFiles.length > 0 && !addAnotherFile && (
                    <button
                        className="block rounded py-2 px-6 bg-gray-300 text-gray-700 ml-auto"
                        onClick={() => setAddAnotherFile(true)}
                        type="button"
                    >
                        Add another file
                    </button>
                )}
                {(existingFiles.length === 0 || addAnotherFile) && (
                    <>
                        <div
                            className={`form-input mb-2 w-full text-s flex cursor-pointer ${
                                validationError ? 'border-red-700 text-red-700' : ''
                            }`}
                            {...getRootProps()}
                        >
                            <input name={category} {...getInputProps()} multiple={false} />
                            {selectedFile ? (
                                selectedFile.name
                            ) : (
                                <span className="opacity-75 hover:text-pink-700">
                                    {isDragActive
                                        ? 'Drop the file here...'
                                        : 'Drag and drop the file here, or click to choose...'}
                                </span>
                            )}
                            {(selectedFileError || uploadError) && (
                                <span className="ml-auto pl-4 text-red-600">
                                    <ExclamationCircle />
                                </span>
                            )}
                        </div>
                        {uploadProgress > 0 && !uploadComplete && (
                            <div className="mb-2">
                                <Progress
                                    percent={Math.floor(uploadProgress * 100)}
                                    colour={uploadError ? 'red' : 'green'}
                                    small
                                />
                            </div>
                        )}
                        {selectedFileError && (
                            <p className="text-sm text-red-700 mb-2">{selectedFileError}</p>
                        )}
                        <p
                            className={`mb-2 text-xs opacity-75 ${
                                selectedFileError ? 'text-red-700' : ''
                            }`}
                        >
                            Maximum file size 5MB. Accepted file types: .pdf .jpg .png .doc .docx
                        </p>
                        {!noExpiry && (
                            <>
                                <label className="text-sm block mb-2">
                                    <span
                                        className={`${
                                            expiryDateError ? 'text-red-700' : 'text-gray-700'
                                        }`}
                                    >
                                        Expiry date (if applicable)
                                    </span>
                                    <input
                                        className="form-input mt-1 block w-full"
                                        value={expiryDate}
                                        onChange={(event) => setExpiryDate(event.target.value)}
                                        placeholder="e.g. 23/03/2026"
                                    />
                                </label>
                                {expiryDateError && (
                                    <p className="text-sm text-red-700 my-2">{expiryDateError}</p>
                                )}
                            </>
                        )}
                        {uploadError && <p className="text-sm text-red-700 mb-2">{uploadError}</p>}
                        {validationError && (
                            <p className="text-sm text-red-700 mb-2">
                                All required documents must be provided.
                            </p>
                        )}
                        <div className="flex justify-end">
                            {existingFiles.length > 0 && (
                                <button
                                    className="block rounded py-2 px-6 bg-gray-300 text-gray-700 ml-auto"
                                    onClick={() => {
                                        setAddAnotherFile(false);
                                        reset();
                                    }}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            )}
                            {selectedFile && (
                                <div className="ml-2">
                                    <SubmitButton
                                        isSubmitting={isUploading}
                                        text="Upload file"
                                        small
                                        className="ml-auto"
                                        onSubmit={onSubmit}
                                        type="button"
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FileInput;
