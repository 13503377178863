import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { InformationCircle } from 'heroicons-react';

import Loading from '../../shared/Loading';
import OrganisationLink from './OrganisationLink';

const MyOrganisations = ({ invites, loading, onboarding }) => {
    const { path } = useRouteMatch();

    if (loading) {
        return (
            <div>
                <Loading />
                <p className="text-sm">Loading organisations, please wait...</p>
            </div>
        );
    }

    const approvedOrganisations = invites.filter((invite) => invite.inviteStatus === 'APPROVED');
    const pendingOrganisations = invites.filter((invite) => invite.inviteStatus !== 'APPROVED');

    return (
        <>
            <h1 className="text-2xl font-light text-pink-700 mb-4">My Organisations</h1>
            <Link
                className="inline-block rounded bg-pink-700 text-white px-6 py-2 mb-6"
                to={`${path}/all`}
            >
                View All Organisations
            </Link>
            {onboarding.attributes.onboardingStatus !== 'COMPLETE' && (
                <div className="text-sm text-blue-700 rounded py-2 px-4 mb-4 bg-blue-100 border border-blue-600 text-left flex">
                    <span>
                        <InformationCircle className="mr-4" />
                    </span>
                    <div>
                        <p className="mb-2">
                            You must complete the{' '}
                            <Link
                                className="text-pink-700 underline"
                                to="/my-account/passport/onboarding/steps/"
                            >
                                onboarding process
                            </Link>{' '}
                            before you can apply to join any new organsiations.
                        </p>
                        <p className="mb-2">
                            However, if you are already assured to work for an organisation you may
                            still be able to make a fast track application to them.
                        </p>
                    </div>
                </div>
            )}
            {invites.length === 0 && (
                <>
                    <p className="mb-2 mt-4">
                        You have not yet requested to join any organisations.
                    </p>
                    <p className="mb-4">Click above to begin.</p>
                </>
            )}
            {approvedOrganisations.length > 0 && (
                <div className="text-left">
                    <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 mb-4">
                        Approved Organisations
                    </h2>
                    {approvedOrganisations.map((invite) => (
                        <OrganisationLink
                            invite={invite}
                            org={invite.organisation}
                            name={invite.organisationName}
                            key={invite.organisation}
                        />
                    ))}
                </div>
            )}
            {pendingOrganisations.length > 0 && (
                <div className="text-left">
                    <h2 className="text-xl font-light text-pink-700 border-b border-pink-700 pt-4 mb-4">
                        Pending Applications
                    </h2>
                    {pendingOrganisations.map((invite) => (
                        <OrganisationLink
                            invite={invite}
                            org={invite.organisation}
                            name={invite.organisationName}
                            key={invite.organisation}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default MyOrganisations;
