import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';

const CompleteNewPassword = ({ didError, onCompleteNewPassword }) => {
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        newPassword: '',
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(8, 'Password | Password must contain at least eight characters.')
            .matches(
                /(?=.*[a-z])(?=.*[A-Z]).+/,
                'Password | Password must contain at lease one upper case and one lower case letter.',
            )
            .required('Password | This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onCompleteNewPassword}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Complete New Password
                        </p>

                        <Input
                            name="newPassword"
                            type={showPassword ? 'text' : 'password'}
                            label="New Password"
                            autocomplete="new-password"
                            disabled={isSubmitting}
                        />

                        <label className="flex items-center text-sm mb-4">
                            <input
                                type="checkbox"
                                checked={showPassword}
                                className="form-checkbox mr-2"
                                onChange={(event) => setShowPassword(event.target.checked)}
                            />
                            Show password
                        </label>

                        <SubmitButton isSubmitting={isSubmitting} text="Set New Password" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CompleteNewPassword;
