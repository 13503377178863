import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';

const SignIn = ({ didError, onSignIn, onToggleForgotPassword, onToggleForgotUsername }) => {
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        userName: '',
        password: '',
    };

    const validationSchema = Yup.object({
        userName: Yup.string()
            .matches(/^\S+$/, 'User Name | User Name cannot contain spaces.')
            .required('User Name | This field is required.'),
        password: Yup.string()
            .min(8, 'Password | Password must contain at least eight characters.')
            .matches(
                /(?=.*[a-z])(?=.*[A-Z]).+/,
                'Password | Password must contain at lease one upper case and one lower case letter.',
            )
            .required('Password | This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSignIn}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, values }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-pink-700 border-b border-pink-700 pt-2 mb-4">
                            Please Sign In
                        </p>

                        <Input
                            name="userName"
                            label="User Name"
                            placeholder="e.g. jane.smith"
                            disabled={isSubmitting}
                        />

                        <p className="text-sm mb-6">
                            <button
                                type="button"
                                className="text-pink-700 underline"
                                onClick={onToggleForgotUsername}
                            >
                                Forgotten your user name?
                            </button>
                        </p>

                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            autocomplete="new-password"
                            disabled={isSubmitting}
                        />

                        <label className="flex items-center text-sm mb-4">
                            <input
                                type="checkbox"
                                checked={showPassword}
                                className="form-checkbox mr-2"
                                onChange={(event) => setShowPassword(event.target.checked)}
                            />
                            Show password
                        </label>

                        <p className="text-sm mb-6">
                            <button
                                type="button"
                                className="text-pink-700 underline"
                                onClick={onToggleForgotPassword}
                            >
                                Forgotten your password?
                            </button>
                        </p>

                        <SubmitButton isSubmitting={isSubmitting} text="Sign In" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SignIn;
