const configs = {
    dev: {
        aws_user_pools_id: 'eu-west-2_YGerVS6BD',
        aws_user_pools_web_client_id: '17ec216jm6d56n85o19j1vjt9l',
    },
    staging: {
        aws_user_pools_id: 'eu-west-2_w0ci4HTUd',
        aws_user_pools_web_client_id: '5o8bvq8el1s7pqggch9b2aj61i',
    },
    prod: {
        aws_user_pools_id: 'eu-west-2_x7m1md0M8',
        aws_user_pools_web_client_id: '1jl05ou44naajb77gm946kttsu',
    },
    js: {
        aws_user_pools_id: 'eu-west-2_8hesaBqmf',
        aws_user_pools_web_client_id: '730ntol9lclo6sj4a9nbt3pqs0',
    },
    bz: {
        aws_user_pools_id: 'eu-west-2_cXX41UriF',
        aws_user_pools_web_client_id: '4sputck9mfto70c040j69dgb5t',
    },
    wb: {
        aws_user_pools_id: 'eu-west-2_duqLIpfLS',
        aws_user_pools_web_client_id: '7humh67b61hea0q42qic022adg',
    },
    sandbox1: {
        aws_user_pools_id: 'eu-west-2_qS1VuJmmU',
        aws_user_pools_web_client_id: '1afumc97kh1bn7e9gqkutd9n19',
    },
    sandbox2: {
        aws_user_pools_id: 'eu-west-2_hpsypI7Br',
        aws_user_pools_web_client_id: 'cufqjvtte4qgqeqidaju7nr2r',
    },
    sandbox3: {
        aws_user_pools_id: 'eu-west-2_itmyKpjuc',
        aws_user_pools_web_client_id: '3adjfvjkve08s0lp6m8h2lnrdg',
    },
};

const { REACT_APP_ENVIRONMENT: env } = process.env;
const subdomainPrefix = env === 'prod' ? '' : `${env}-`;

const config = {
    aws: {
        aws_project_region: 'eu-west-2',
        aws_cognito_region: 'eu-west-2',
        ...configs[process.env.REACT_APP_ENVIRONMENT],
    },
    apiEndpoint: `https://${subdomainPrefix}onboarding.docabode.com/api`,
};

export default config;
